.cookie-banner {
    position: fixed;
    bottom: var(--app-nav-height);
    left: var(--offset-x);
    background-color: $black-200;
    padding: 24px;
    display: flex;
    align-items: center;
    gap: 22px;
    z-index: 4;
    width: 440px;

    @include mobile {
        flex-direction: column;
        left: 37px;
        width: calc(100% - 74px);
        gap: 16px;
        padding: 16px;
        bottom: calc(var(--app-nav-height) + 10px);
    }
}

.cookie-banner__text {
    color: rgba($white, 0.4);
}

.cookie-link {
    color: $white;
    --bg-size: 100% 100%;
    --bg-size-hover: 0% 100%;
    --underline-height: 1px;
    background-image: linear-gradient(
        transparent calc(100% - var(--underline-height)),
        currentColor var(--underline-height)
    );
    background-repeat: no-repeat;
    background-size: var(--bg-size);
    transition: background-size $app-default-duration $app-default-easing;

    @media (any-hover: hover), (hover: hover) and (pointer: fine) {
        &:not(:disabled):hover {
            --color: var(--color-hover);
            background-size: var(--bg-size-hover);
        }
    }
}
