.index-page {
    .footer {
        display: none;
    }
}

.index-page.no-scroll .header {
    padding-right: var(--offset-x);
}

.parting-page-content {
    display: flex;
    flex-direction: column;
}

.parting-page-hero {
    position: relative;
    min-height: calc(100vh - var(--app-nav-small-height));
    min-height: calc(100svh - var(--app-nav-small-height));
}

.parting-page-text {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: calc(var(--header-height) + 56px);
    transform: translate(-50%, 0);
    text-align: center;
    max-width: 201px;
    text-wrap: balance;
    pointer-events: none;

    @media screen and (max-width: 1366px) {
        max-width: 169px;
    }

    @media screen and (max-width: 1024px) {
        position: static;
        transform: none;
        margin-bottom: 34px;
        margin-top: calc(var(--header-height) + 50px);
        margin-left: auto;
        margin-right: auto;
        pointer-events: auto;
    }

    @include mobile {
        margin-top: calc(var(--header-height) + 35px);
    }

    @media screen and (max-width: 767px) {
        max-width: 149px;
    }
}

.parting-list {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;

    @include mobile {
        justify-content: space-around;
    }
}

.parting-list__item {
    flex: 0 0 50%;
    display: flex;

    @media screen and (max-width: 1024px) {
        flex-grow: 1;
        flex-shrink: 1;
    }

    @include mobile {
        flex: none;
    }

    > .parting-item {
        width: 100%;
    }
}

.parting-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 54px var(--offset-x);

    @include hover {
        .hat {
            opacity: 1;
            transform: translateY(0);
        }

        .parting-item__img {
            svg {
                [fill]:not([fill='none']) {
                    fill: $primary;
                }
            }
        }
    }

    &:active {
        .hat {
            opacity: 1;
            transform: translateY(0);
        }

        .parting-item__img {
            svg {
                [fill]:not([fill='none']) {
                    fill: $primary;
                }
            }
        }
    }

    @media (hover: none) {
        .hat {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.parting-item__img-container {
    width: 144px;
    margin-bottom: 86px;

    @media screen and (max-width: 1366px) {
        width: 120px;
        margin-bottom: 93px;
    }

    @media screen and (max-width: 1024px) {
        margin-bottom: 59px;
        width: 110px;
    }

    @include mobile {
        width: 80px;
        margin-bottom: 34px;
    }
}

.parting-item__img-wrapper {
    --aspect-ratio: 1;
    --object-fit: contain;
}

.parting-item__img {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 100%;
        height: auto;
        max-height: 100%;

        [fill]:not([fill='none']) {
            fill: #fff;
            transition: fill $app-default-duration $app-default-easing;
        }
    }
}

.review-hint {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: center;
    text-align: center;

    @include laptop {
        gap: 12px;
        bottom: 32px;
    }

    @include mobile {
        bottom: 28px;
    }
}

.review-hint__text {
    max-width: 215px;

    @include laptop {
        max-width: 186px;
    }
}

@keyframes mouseBall {
    from {
        transform: translate(-50%, 0px);
    }
    to {
        transform: translate(-50%, 2px);
    }
}

.review-hint__mouse {
    width: 17px;
    height: 26px;
    border-radius: 30px;
    border: 1px solid #fff;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #fff;
        top: 4px;
        left: 50%;
        transform: translateX(-50%);
        animation: mouseBall 1s ease-in-out infinite both alternate-reverse;
    }
}

.delete-all-btn {
    padding: 9px 50px;
    border: 1px solid rgba($white, 0.3);

    @include mobile {
        padding: 9px 22px;
    }
}
