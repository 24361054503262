.cursor {
    position: fixed;
    z-index: 9999;
    pointer-events: none;
    top: 0;
    left: 0;

    @media (pointer: coarse) {
        display: none;
    }

    svg {
        [fill]:not([fill='none']) {
            fill: var(--theme-color);
        }

        [stroke]:not([stroke='none']) {
            stroke: var(--theme-color);
        }
    }
}

.cursor-text__inner {
    opacity: 0.7;
    position: absolute;
    top: 20px;
    left: 20px;
    color: $white;
    opacity: 0.7;
    white-space: nowrap;
    text-transform: uppercase;
    transform-origin: left top;
}
