.callback-and-phone-buttons {
    position: fixed;
    right: var(--offset-x);
    bottom: var(--offset-x);
    display: flex;
    gap: 2px;
    z-index: 3;
    transition: transform 1.5s $app-default-easing 0.25s;

    .no-scroll & {
        padding-right: var(--actual-scrollbar-width, 0px);
    }

    @include tablet {
        --gap: 1px;
        width: calc(51.5% - var(--offset-x) - var(--gap));
    }

    @include horizontal-tablet {
        width: auto;
    }

    @include mobile {
        width: calc(100% - var(--offset-x) * 2);
        gap: 8px;
    }

    @include small-mobile {
        gap: 4px;
    }
}

.callback-and-phone-buttons {
    &.callback-bottom {
        transform: translateY(200%);
        transition-delay: 0s;
    }
}
