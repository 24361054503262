.article-wrapper {
    margin-inline: auto;
    max-width: 780px;
    width: 100%;

    @include laptop {
        max-width: 620px;
    }

    @include tablet {
        max-width: 546px;
    }

    @include mobile {
        max-width: 100%;
    }
}
