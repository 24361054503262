.compare-page {
    .page {
        --top-offset: 56px;
        padding-top: calc(var(--header-height) + var(--top-offset));

        @include laptop {
            --top-offset: 48px;
        }

        @include tablet {
            --top-offset: 38px;
        }

        @include mobile {
            --top-offset: 16px;
        }
    }

    .controls-filter-btns {
        display: none;
    }
}

.compare-title {
    margin-top: 122px;
    text-align: center;

    @include laptop {
        margin-top: 109px;
    }

    @include laptop {
        margin-top: 96px;
    }

    @include mobile {
        margin-top: 113px;
    }
}

.compare-tab-controls {
    margin-top: 64px;
    display: flex;
    gap: 24px;
    justify-content: center;

    .btn {
        margin-inline: 0px;
    }

    @include laptop {
        margin-top: 40px;
        gap: 20px;
    }

    @include tablet {
        margin-left: calc(var(--offset-x) * -1);
        padding-inline: var(--offset-x);
        width: calc(100% + var(--offset-x) * 2);
        overflow-x: auto;
        @include hidden-scrollbar;
    }

    @include mobile {
        margin-top: 54px;
    }
}

.empty-compare {
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.catalog-wrapper {
    .empty-compare {
        .compare-title {
            width: 100%;
            text-align: center;
        }
    }
}

.compare-title--empty {
    margin-bottom: 36px;

    @include laptop {
        margin-bottom: 24px;
    }
}

.compare-empty-description {
    margin-bottom: 64px;

    @include laptop {
        margin-bottom: 54px;
    }

    @include mobile {
        margin-bottom: 44px;
    }
}
