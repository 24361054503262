.article-body {
    display: flex;
    flex-direction: column;
    gap: 106px;
    margin-top: 110px;

    @include laptop {
        margin-top: 100px;
        gap: 84px;
    }

    @include tablet {
        gap: 80px;
        margin-top: 84px;
    }

    @include mobile {
        gap: 80px;
        margin-top: 80px;
    }
}

.article-body__top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 48px;

    @include mobile {
        flex-direction: column-reverse;
        align-items: flex-start;
        padding-inline: var(--offset-x);
    }
}

.article-constructor {
    display: flex;
    flex-direction: column;
    gap: 110px;

    @include laptop {
        gap: 100px;
    }

    @include tablet {
        gap: 80px;
    }

    @include mobile {
        gap: 60px;
    }
}

.article-wysiwyg {
    h2 {
        font-weight: 400;
    }

    p,
    ul,
    ol {
        @extend .text-l;
    }

    p {
        line-height: 2;

        @include laptop {
            line-height: 1.86;
        }

        @include tablet {
            line-height: 1.71;
        }

        @include mobile {
            line-height: 1.69;
        }
    }

    ol,
    ul {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    ol {
        padding-inline-start: 20px;

        li {
            text-indent: 10px;
        }
    }

    ul {
        list-style: none;
        padding-inline-start: 0px;

        li {
            position: relative;
            --left-offset: 24px;
            padding-left: var(--left-offset);

            &::before {
                --size: 4px;
                content: '';
                position: absolute;
                top: 0.4em;
                left: calc(var(--left-offset) / 2 - var(--size));
                height: var(--size);
                width: var(--size);
                background-color: $gray;
                border-radius: 50%;
            }
        }
    }
}

.constructor-block--media {
    max-width: 1248px;
    width: 100%;
    margin-inline: auto;
    background-color: $black-100;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-width: 100%;
        height: 100%;
        object-fit: contain;
    }

    @include laptop {
        max-width: 868px;
    }

    @include tablet {
        max-width: 100%;
    }
}

.article-body__left {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include tablet {
        gap: 20px;
    }
}

.article-tags-list {
    display: flex;
    flex-wrap: wrap;
    gap: 14px 8px;

    @include laptop {
        gap: 14px 6px;
    }

    @include tablet {
        gap: 6px;
    }
}

.article-tag {
    opacity: 0.6;
    transition: opacity 0.33s ease;

    @include hover {
        opacity: 1;
    }
}

.article-left__info--opacity {
    opacity: 0.6;
}
