.constructor-video {
    position: relative;
    overflow: hidden;
    overflow: clip;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.constructor-video__control {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-inline: 0;
    transition: opacity 0.25s ease-in;

    .constructor-video--idle & {
        opacity: 0;
    }
}

.constructor-video__video video {
    max-width: 100%;
}
