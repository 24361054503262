.input-block {
    --autofill-bg-color: #{$black-100};
    --autofill-color: #{$white};
    position: relative;
    display: block;

    @include hover {
        .input-block__input {
            border-color: $white;
        }
    }
}

.input-block__input {
    width: 100%;
    border: none;
    border-bottom: 1px solid rgba($white, 0.2);
    padding: 8px 0;
    background-color: transparent;
    color: inherit;
    transition: border-color $app-default-duration $app-default-easing;
    border-radius: 0px;

    @extend .text-s;

    &:focus {
        border-color: $white;
    }

    &:focus,
    &.not-empty {
        & + .input-block__label {
            transform: translateY(-200%);
        }
    }

    &:focus-visible,
    &:active {
        outline: none;
    }

    &:-webkit-autofill {
        transition: none;
        caret-color: var(--autofill-color);
        border-color: rgba($white, 0.3);

        &,
        &:focus,
        &:active,
        &:hover {
            transition: background-color 9999s ease; // суперкостыль, чтобы пофиксить autofill в iOS
            color: var(--autofill-color) !important;
            -webkit-text-fill-color: var(--autofill-color) !important;
            box-shadow: inset 0 0 0 1000px var(--autofill-bg-color) !important;
        }

        &:focus,
        &:active,
        &:hover {
            border-color: $white;
        }
    }

    &::-webkit-credentials-auto-fill-button {
        visibility: hidden;
    }

    @include laptop {
        padding: 10px 0;
    }
}

.input-block-white {
    .input-block__input {
        border-bottom: 1px solid $white;
    }

    .input-block__icon {
        svg path {
            &[stroke] {
                stroke: $white;
            }

            &[fill] {
                fill: $white;
            }
        }
    }
}

.input-block--icon .input-block__input {
    padding-right: 29px;

    @include mobile {
        padding-right: 15px;
    }
}

.input-block__label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    text-transform: uppercase;
    transition:
        transform $app-default-duration $app-default-easing,
        opacity $app-default-duration $app-default-easing;

    @extend .text-s;
}

.input-block__message,
.app-message {
    position: absolute;
    top: 1em;
    right: 0;
    z-index: 1;
    font-size: 11px;
    color: $danger;
    letter-spacing: -0.03em;
    line-height: 1.27;
}

.input-block__icon {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    --size: 15px;
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        right: -3px;
    }
}

.textarea {
    resize: none;
    @include thick-scrollbar();
}
