$app-default-duration: 0.25s;
$app-default-easing: cubic-bezier(0.25, 0.25, 0, 1);

$btn-disabled-opacity: 0.5;

// scss-docs-start theme-color-variables
$primary: #c3af80;
$gray-brown: #7f7d6c;
$gray: #d9d9d9;
$brown: #544d3d;
$black: #0d0d0d;
$white: #f4f4f4;
$black-100: #181818;
$black-200: #212121;
$danger: #ff451c;
// scss-docs-end theme-color-variables

$body-bg: $black !default;
$body-color: $white !default;

// scss-docs-start font-variables
$font-fallback:
    system-ui,
    -apple-system,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    'Liberation Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji' !default;
$font-family: 'TT Neoris', $font-fallback !default;
$font-mono: 'PP Neue Montreal Mono', $font-fallback !default;
// scss-docs-end theme-color-variables
