.contacts-page {
    .footer {
        display: none;
    }

    .page {
        --offset-top: 54px;
        padding-top: calc(var(--header-height) + var(--offset-top));
        height: 100vh;
        padding-bottom: 0;

        @include laptop {
            --offset-top: 52px;
        }

        @include tablet {
            --offset-top: 40px;
        }

        @include mobile {
            --offset-top: 30px;
        }
    }

    .footer-warning--desktop {
        @include tablet {
            display: block;
            left: 50%;
            bottom: 100px;
            transform: translate(-50%);
            max-width: 250px;
            text-align: center;
            text-decoration: none;
        }

        @include mobile {
            bottom: 75px;
            width: 250px;
        }
    }

    .footer-socnets {
        &.footer-socnets--mobile {
            flex-wrap: wrap;
        }

        @include mobile {
            gap: 16px 0;
            .clip-container {
                &.button-clip-container {
                    --clip-container-offset-x: 0px;
                }
            }

            .footer-socnets__item {
                width: calc(100% / var(--socnets-count));
            }
        }
    }

    .button-clip-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.contacts-page.no-scroll .header {
    padding-right: var(--offset-x);
}

.contacts-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    overflow: clip;
}

.contacts-content-top-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.contacts-content-top {
    position: relative;
    padding-bottom: 61px;
    margin-top: 6.1vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include laptop {
        padding-bottom: 45px;
        margin-top: 5.5vh;
    }

    @include tablet {
        margin-top: 10vh;
    }

    @include vertical-tablet {
        padding-bottom: 67px;
        margin-top: 1.26vh;
    }

    @include mobile {
        --size: 110vw;
        margin-top: 0;
        gap: 44px;
        padding-bottom: 0;
        justify-content: center;
        height: var(--size);
    }

    @include horizontal-mobile {
        gap: 24px;
    }

    @include small-mobile {
        padding-bottom: 100px;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        border: 1px solid rgba($white, 0.4);
        border-radius: 50%;
        --size: 76.77vw;
        width: var(--size);
        height: var(--size);

        @include laptop {
            --size: 79vw;
        }

        @include vertical-tablet {
            --size: 131.77vw;
        }

        @include mobile {
            --size: 110vw;
            bottom: unset;
            top: 0;
        }

        @include small-mobile {
            top: -50px;
        }
    }
}

.contacts-content-bottom {
    position: relative;
    padding-top: 100px;

    @include laptop {
        padding-top: 80px;
    }

    @include vertical-tablet {
        padding-top: 90px;
    }

    @include mobile {
        padding-top: 0;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        border: 1px solid rgba($white, 0.4);
        border-radius: 50%;
        --size: 44.63vw;
        width: var(--size);
        height: var(--size);

        @include laptop {
            --size: 45.93vw;
        }

        @include tablet {
            --size: 76.56vw;
        }

        @include small-mobile {
            top: -50px;
        }
    }
}

.contacts-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.contacts-canvas-el {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.contacts-socnet {
    @include responsive-font-size(15px, 17px, 1280px, 1920px);
    line-height: 1.17;
    letter-spacing: -0.03em;

    @include mobile {
        font-size: 13px;
        line-height: 1.15;
    }
}

.contacts-to-main {
    z-index: 2;
    position: relative;
}

.contacts-links-wrapper {
    position: absolute;
    bottom: 34px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    @include tablet {
        bottom: 140px;
    }

    @include mobile {
        bottom: 105px;
    }

    .clip-container {
        &.button-clip-container {
            --clip-container-offset-x: 0px;
            overflow: visible;
        }
    }
}

.contacts-links-list {
    display: flex;
    gap: 24px;

    @include mobile {
        gap: 8px;
    }
}
