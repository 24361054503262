/* stylelint-disable */

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace +
            str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: woff2 woff) {
    $src: null;
    $extmods: (
        eot: '?',
        svg: '#' + str-replace($name, ' ', '_'),
    );
    $formats: (
        otf: 'opentype',
        ttf: 'truetype',
    );

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + '.' + $extmod)) format(quote($format)), comma);
    }

    @font-face {
        font-display: swap;
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}

/* stylelint-enable */

@include font-face('PP Neue Montreal Mono', '/fonts/PPNeueMontrealMono-Medium', 500, null, woff2);
@include font-face('TT Neoris', '/fonts/TT_Neoris_Regular', 400, null, woff2);
@include font-face('TT Neoris', '/fonts/TT_Neoris_Medium', 500, null, woff2);
