.phone-button {
    display: flex;
    align-items: center;
    justify-content: center;
    --size: 48px;
    width: var(--size);
    height: var(--size);
    background-color: #fff;
    transition: transform 0.4s ease;
    z-index: 1;

    @include large-desktop {
        --size: 72px;

        .phone-button__icon {
            --size: 40px;
        }
    }

    @include mobile {
        --size: 40px;
    }
}

.phone-button__icon {
    --size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--size);
    height: var(--size);
}

.phone-button-popup {
    visibility: hidden;
    transition: visibility 0s ease;
    transition-delay: 0.5s;
    z-index: 17;

    .popup-container {
        position: absolute;
        top: 0;
        right: 0;
        left: unset;
        width: 414px;
        overflow: hidden;
        pointer-events: none;

        @include large-desktop {
            width: 25.625vw;
        }

        @include laptop {
            width: 25.78vw;
        }

        @include tablet {
            width: 50%;
        }

        @include mobile {
            width: calc(100% - var(--offset-x) * 2);
            right: var(--offset-x);
            top: unset;
            bottom: 16px;
            bottom: calc(16px + 100lvh - 100dvh);
            height: auto;
        }
    }

    .popup-overlay {
        background-color: $black;
        opacity: 0;
        transition: opacity 0.5s ease;
    }

    &.popup--opened {
        visibility: visible;
        transition-delay: 0s;

        .popup-overlay {
            opacity: 0.74;
        }
    }
}

.phone-button-text {
    opacity: 0.65;
    text-transform: uppercase;
}

.phone-button-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    padding: 36px 40px;
    background-color: $black-200;
    gap: 15px;

    @include hover {
        .phone-button-item__icon {
            transform: translateX(-4px);
        }

        .phone-button-item__text {
            transform: translateX(4px);
        }
    }

    @include mobile {
        padding: 20px;
        height: 56px;
    }
}

.phone-button-close {
    justify-content: space-between;
    padding-block: 40px;

    @include hover {
        .square-bracket--left {
            transform: translateX(-3px);
        }

        .square-bracket--right {
            transform: translateX(3px);
        }

        .btn__inner {
            transform: translateY(-110%);
        }

        .btn__copy {
            transform: translateY(0);
        }
    }

    @include mobile {
        display: none;
    }
}

.phone-button-list {
    display: flex;
    flex-direction: column;
    gap: 1px;
    pointer-events: auto;
}

.btn.phone-button-close__icon {
    font-size: 10px;
}

.phone-button-item__icon {
    --size: 20px;
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.4s ease;

    svg {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;

        path {
            &[fill] {
                fill: $white;
            }

            &[stroke] {
                stroke: $white;
            }
        }
    }
}

.phone-button-item__text {
    text-transform: uppercase;
    transition: transform 0.4s ease;
}

.phone-button-close-mobile {
    display: none;
    margin-top: 16px;

    @include mobile {
        display: flex;
        height: 48px;
    }
}
