* {
    @include scrollbar($white);
}

html {
    background-color: $body-bg;
    color: $body-color;
    overflow: overlay;

    &.no-scroll {
        overflow: hidden;
        padding-right: var(--actual-scrollbar-width, 0px);
    }
}

body {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    min-height: calc(var(--vh, 1vh) * 100);
    font-family: $font-family;
    display: flex;
    flex-direction: column;
    -webkit-font-size-adjust: 100%;
    font-size-adjust: 100%;
}

.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}

:focus {
    outline-color: currentColor;
}

:focus-visible {
    @include outline();
}

// ::selection {
//     background-color: rgba(#fff, 0.2);
// }

#__next {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.main {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.page {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    white-space: nowrap;
    border: 0;
    clip: rect(0 0 0 0);
}

.wrapper {
    display: block;
    width: 100%;
    max-width: 2560px;
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--offset-x);
    padding-right: var(--offset-x);
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.canvas-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    user-select: none;
}

.list-unstyled {
    list-style: none;
    padding: 0;
    margin: 0;
}

.pointer-events-none {
    pointer-events: none !important;
}

.pointer-events-auto {
    pointer-events: auto !important;
}

// https://nextjs.org/docs/api-reference/next/image#known-browser-bugs
@supports (font: -apple-system-body) and (-webkit-appearance: none) {
    img[loading='lazy'] {
        clip-path: inset(0.6px);
    }
}

.clip-container {
    overflow: hidden;
    overflow: clip;

    &.button-clip-container {
        --clip-container-offset-x: 20px;
        padding-left: var(--clip-container-offset-x);
        padding-right: var(--clip-container-offset-x);
        margin-left: calc(-1 * var(--clip-container-offset-x));
        margin-right: calc(-1 * var(--clip-container-offset-x));
    }
}

.clip-container__el {
    transition: transform 1s $app-default-easing;
}

.curtains {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 14;
    display: flex;
    opacity: 0;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    background-color: $black;
    transition: opacity 1s $app-default-easing;

    .catalog-preloader-main-circle {
        position: absolute;
        --size: 255px;
    }
    .catalog-preloader-medium-circle {
        --size: 126px;
    }
    .catalog-preloader-medium-circle-inner {
        --size: 30px;
        transform-origin: -45px;
    }

    .catalog-preloader-small-circle {
        --size: 24px;
        transform-origin: -117px;
    }

    .is-page-leaving & {
        opacity: 1;
    }

    html.no-scroll & {
        padding-right: var(--scrollbar-width, 0px);
    }
}

.close-button,
.btn-geometry-square-bracket.close-button {
    font-size: 16px;

    .btn__text {
        font-size: 16px;
    }
}

.sitemap-page {
    .footer {
        display: none;
    }

    .page {
        --offset: 80px;
        padding-top: calc(var(--header-height) + var(--offset));
    }
}

.back-button-clickable {
    @include clickable;
}
