.file-uploader-label {
    cursor: pointer;
    width: 100%;
    border: none;
    padding: 8px 0;
    transition:
        border-color $app-default-duration $app-default-easing,
        transform $app-default-duration $app-default-easing;
    display: flex;
    align-items: center;
    gap: 12px;

    @include hover {
        border-color: $white;
    }
}

.file-uploader-label__text {
    text-transform: uppercase;
}

.file-uploader-label__icon {
    --size: 14px;
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 100%;
        height: 100%;
    }
}

.file-uploader-list {
    display: flex;
    flex-wrap: wrap;
    gap: 6px 12px;
    width: 100%;
    max-height: 69px;
    overflow-x: hidden;
    overflow-y: auto;
    @include thick-scrollbar();
    position: relative;
    z-index: 1;
    margin-top: 8px;
}

.file-uploader-file {
    display: flex;
    gap: 4px;
    align-items: flex-end;
}

.file-uploader-file__name {
    font-size: 12px;
    letter-spacing: -0.03em;
    line-height: 1.5;
}

.file-uploader-file__remove-btn {
    font-size: 10px;
    font-weight: 500;
    font-family: $font-mono;
    text-transform: uppercase;
    opacity: 0.7;
    transition: opacity $app-default-duration $app-default-easing;
    line-height: 18px;
    @include clickable(relative, 16px);

    @include hover {
        opacity: 0.9;
    }

    @include tablet {
        font-size: 16px;
    }

    @include mobile {
        font-size: 14px;
    }
}
