.wysiwyg {
    p:not([class]) {
        &:not(:first-child) {
            margin-top: 1.33em;
        }

        &:not(:last-child) {
            margin-bottom: 1.33em;
        }
    }

    img:not([class]) {
        @extend .img-fluid;
    }

    a:not([class]) {
        @extend .link;
        @extend .link--underlined;
    }

    h1 {
        &:not(:last-child) {
            margin-bottom: 1.1em;
        }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        &:not(:first-child) {
            margin-top: 1em;
        }

        &:not(:last-child) {
            margin-bottom: 1em;
        }
    }

    ul:not([class]) {
        @extend .hint-list;

        &:not(:first-child) {
            margin-top: 1.33em;
        }

        &:not(:last-child) {
            margin-bottom: 1.33em;
        }
    }
}
