.reviews-popup {
    visibility: hidden;
    transition: visibility 0s ease;
    transition-delay: 0.5s;
    z-index: 17;

    .popup-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        background-color: $black-200;
        padding: 20px var(--offset-x);
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include horizontal-mobile {
            overflow-y: auto;
        }
    }

    &.popup--opened {
        visibility: visible;
        transition-delay: 0s;

        .popup-overlay {
            opacity: 0.74;
        }
    }
}

.reviews-popup-top {
    position: relative;

    .review-card__type {
        top: 0;
        left: 0;
    }
}

.review-popup-content {
    position: relative;
    margin-inline: calc(var(--offset-x) * -1);
    padding-inline: var(--offset-x);
    width: calc(100% + var(--offset-x) * 2);
    height: 0;
    padding-top: 146%;

    @include horizontal-mobile {
        margin-inline: auto;
        width: 40%;
        padding-inline: 0;
        padding-top: 56.25%;
    }
}

.reviews-popup-close {
    position: absolute;
    top: 20px;
    right: var(--offset-x);
}
