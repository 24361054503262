.popup {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100lvh;
    overflow: hidden;
}

.popup-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: inherit;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.popup-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
}

.popup-close-btn {
    --size: 30px;
    position: absolute;
    z-index: 1;
    top: 38px;
    right: var(--offset-x);
    z-index: 1;
    width: var(--size);
    height: var(--size);

    @include hover {
        svg {
            transform: scale(0.8);
        }
    }

    @media screen and (max-width: 1366px) {
        --size: 22px;
        top: 28px;
    }

    @include tablet {
        top: 36px;
    }

    @media screen and (max-width: 767px) {
        top: 31px;
    }

    svg {
        width: 100%;
        height: auto;
        transition: transform 0.3s $app-default-easing;
    }
}
