.app-nav-popup {
    visibility: hidden;
    transition: visibility 0s ease;
    transition-delay: 1s;
    z-index: 12;

    .popup-container {
        color: $white;
        position: absolute;
        pointer-events: none;

        @include tablet {
            overflow: visible;
        }
    }

    .popup-overlay {
        background-color: rgba($black, 0.7);
        transition: opacity 0.6s $app-default-easing;
        transition-delay: 0.2s;
        opacity: 0;
    }

    &.popup--opened {
        visibility: visible;
        transition-delay: 0s;

        .popup-overlay {
            transition-delay: 0s;
            transition-duration: 1s;
            opacity: 1;
        }
    }
}

.app-nav-popup-content {
    position: absolute;
    background-color: $black-100;
    border: 1px solid rgba($white, 0.4);
    border-top-color: transparent;
    width: var(--app-nav-catalog-button-width);
    padding-block: 64px;
    overflow: clip;
    pointer-events: auto;

    @include tablet {
        overflow-y: auto;
        // Перебиваю инлайновые стили, так как на десктопе положение попапа считается через js
        top: 0 !important;
        left: 0 !important;
        width: 100%;
        height: 100%;
        border: none;
        padding-top: calc(var(--header-height) + 32px);
        padding-bottom: calc(var(--header-height) + 116px); // 116 пикселей потому что смотрится красиво
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @include mobile {
        padding-bottom: calc(var(--header-height) + 64px);
    }
}

.app-nav-popup-link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: 30px 14px;
    text-align: center;
    text-transform: uppercase;
    transition: background-color $app-default-duration $app-default-easing;

    @include hover {
        background-color: $black;
    }

    @include tablet {
        padding-block: 22px;
    }

    @include mobile {
        min-width: 100vw;
        min-width: 100svw;
    }
}

.app-nav-popup-link__el {
    @include mobile {
        font-size: 13px;
    }
}

.app-nav-time {
    justify-content: space-between;
}

.app-nav-time__text {
    opacity: 0.65;
}

.burger-buttons {
    position: absolute;
    top: 0;
    right: calc(var(--header-height) - var(--scrollbar-width));
    height: var(--header-height);
    display: none;

    @include tablet {
        display: flex;
    }

    @include mobile {
        width: calc(100% - var(--header-height) + var(--scrollbar-width));
    }

    .btn {
        height: var(--header-height);

        @include mobile {
            flex-grow: 1;

            .btn__inner,
            .btn__copy {
                width: 100%;
                justify-content: center;
            }
        }

        &:not(:last-child) {
            border-right: 1px solid $black;
        }
    }
}

.app-nav-burger-callback-button {
    height: var(--header-height);
}
