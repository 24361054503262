.compare-table {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.compare-row {
    display: flex;
    width: 100%;
    background-color: $black-100;
}

.compare-cell {
    --padding-x: 40px;
    --padding-y: 50px;
    padding: var(--padding-y) var(--padding-x);
    display: flex;
    flex-direction: column;
    gap: 46px;
    width: 25%;

    @include laptop {
        gap: 30px;
        --padding-y: 36px;
        --padding-x: 20px;
    }

    @include tablet {
        width: 50%;
    }

    @include mobile {
        gap: 20px;
        --padding-y: 42px;
        --padding-x: 16px;
    }

    .compare-cell__content::after {
        content: '';
        position: absolute;
        bottom: -10px;
        right: calc(var(--padding-x) * -1);

        background-color: $black;
        height: 100%;
        min-height: 52px;
        width: 2px;

        @include mobile {
            min-height: 32px;
        }
    }
}

.compare-cell__content {
    margin-top: auto;
    position: relative;
    display: flex;
}

.compare-cell__title {
    opacity: 0.6;
}
