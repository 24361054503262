.main-catalog-slider {
    min-height: 100vh;
    overflow: hidden;
    overflow: clip;
    color: $white;
    background-color: $gray-brown;
    position: relative;
    display: flex;
    flex-direction: column;
}

.main-catalog-slider-canvas-wrapper {
    position: absolute;
    pointer-events: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
}

.main-catalog-slider-canvas {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow: clip;
}

.main-catalog-slider-canvas__el {
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease 0.1s;

    &.is-visible {
        opacity: 1;
    }
}

.main-catalog-slider__canvas-bracket {
    --left: 0px;
    position: absolute;
    top: 50%;
    left: calc(50% + var(--left));
    transform: translate(-50%, -50%);
    height: auto;
}

.main-catalog-slider__canvas-bracket--prev {
    transform: translate(-50%, -50%) rotate(180deg);
}

.main-catalog-slider__img-wrapper {
    --aspect-ratio: 1;
    --object-fit: contain;
}

.main-catalog-slider__content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    user-select: none;
    touch-action: pan-y;

    @media screen and (max-width: 960px) {
        padding-top: var(--header-height);
        padding-bottom: calc(var(--app-nav-height) + 32px);
        flex-direction: column;
        justify-content: center;
    }
}

.main-catalog-slider__title {
    text-transform: uppercase;
    position: absolute;
    top: Min(142px, 10vh);
    left: 50%;
    transform: translate(-50%, 0);

    @media screen and (max-width: 960px) {
        position: static;
        transform: none;
        text-align: center;
        margin-bottom: 80px;
    }
}

.main-catalog-slider__row {
    width: 80%;
    display: flex;
    align-items: center;
    margin: auto;
    gap: 30px;

    @media screen and (max-width: 1440px) {
        width: 85%;
    }

    @media screen and (max-width: 1280px) {
        width: 100%;
    }

    @media screen and (max-width: 960px) {
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }
}

.main-catalog-slider__left {
    margin-right: auto;
    flex: 0 1 240px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 62px;
    text-transform: uppercase;

    @media screen and (max-width: 960px) {
        flex: none;
        margin-right: 0;
        margin-bottom: auto;
        text-align: center;
        align-items: center;
        gap: 18px;
        max-width: 30ch;
    }
}

.main-catalog-slider__right {
    margin-left: auto;
    flex: 0 1 240px;

    @media screen and (max-width: 960px) {
        margin-left: 0;
        margin-top: auto;
        flex: none;
        min-width: 250px;
        max-width: 100%;
        min-height: 120px;
    }

    @media screen and (max-width: 420px) {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (max-width: 350px) {
        width: 80%;
    }
}

.main-catalog-slider__center {
    margin-left: auto;
    margin-right: auto;
    flex: 0 0 auto;
    position: relative;

    @media screen and (max-width: 960px) {
        margin-left: 0;
        margin-right: 0;
        margin-top: auto;
        margin-bottom: auto;
    }
}

.main-catalog-slide-charasteristics {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 26px;
}

.main-catalog-slide-charasteristic {
    width: 100%;
}

.main-catalog-slide-charasteristic-inner {
    display: flex;
    gap: 24px;
    align-items: flex-start;
    text-align: right;

    @media screen and (max-width: 960px) {
        text-align: left;
        gap: 28px;
    }
}

.main-catalog-slide-charasteristic__key {
    flex: 1 1 auto;
    opacity: 0.55;
    display: flex;
    padding-left: 30px;

    @media screen and (max-width: 960px) {
        flex: 0 0 76px;
        padding-left: 0;
    }
}

.main-catalog-slide-charasteristic__key-item {
    position: relative;
    display: inline;

    @media screen and (min-width: 961px) {
        &::before {
            content: '';
            position: absolute;
            top: 0.59em;
            transform: translate(0, -50%);
            right: calc(100% + 6px);
            border-radius: 50%;
            width: 5px;
            height: 5px;
            background-color: currentColor;
        }
    }

    @media screen and (max-width: 960px) {
        &::before {
            content: '[ ';
        }

        &::after {
            content: ' ]';
        }
    }
}

.main-catalog-slide-charasteristic__value {
    flex: 0 0 160px;

    @media screen and (max-width: 960px) {
        flex: 1 1 auto;
        min-width: 126px;
        text-align: right;
    }

    &.main-catalog-slide-charasteristic__value--material {
        @media screen and (max-width: 767px) {
            min-height: 3 * 1.28em;
        }
    }
}

.main-catalog-slider__pagination {
    position: absolute;
    z-index: 1;
    bottom: calc(var(--app-nav-height) + 52px);
    left: 50%;
    transform: translate(-50%, 0);

    @media screen and (max-width: 1440px) {
        bottom: var(--app-nav-height);
    }

    @media screen and (max-width: 960px) {
        position: static;
        margin-left: auto;
        margin-right: auto;
        transform: none;
        margin-top: 91px;
    }
}

.main-catalog-slider-link-wrapper {
    padding: 0px 20px;
    margin-left: -20px;

    @include mobile {
        margin-left: 0;
    }
}

.main-catalog-slider-item__name {
    display: flex;
    flex-wrap: wrap;
    gap: 0 0.25em;

    @media screen and (max-width: 960px) {
        justify-content: center;
    }

    @media screen and (max-width: 767px) {
        align-items: center;
        min-height: 2 * 1.125em;
    }
}

.main-catalog-price {
    display: flex;
}
