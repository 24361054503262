.header {
    position: fixed;
    z-index: 15;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--header-height);
    pointer-events: none;

    .no-scroll & {
        padding-right: calc(var(--scrollbar-width, 0px) + var(--offset-x));

        @include tablet {
            padding-right: var(--offset-x);
        }

        @include horizontal-large-tablet {
            padding-right: var(--offset-x);
        }
    }

    @include tablet {
        pointer-events: none;
    }
}

.header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: var(--header-offset);

    @include tablet {
        padding-block: 0;
    }
}

.header-logos-wrapper {
    flex: 0 0 30%;
    display: flex;
    transition: opacity $app-default-duration $app-default-easing;
    pointer-events: all;

    @include tablet {
        pointer-events: all;
    }

    @include mobile {
        .burger-opened & {
            opacity: 0;
            pointer-events: none;
        }
    }
}

.header-logos {
    display: flex;
    gap: 48px;
    position: relative;

    @include laptop {
        gap: 40px;
    }

    @include tablet {
        padding-block: var(--header-offset);
    }

    @include mobile {
        gap: 24px;
    }

    &::before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba($white, 0.6);
        width: 1px;
        height: 20px;

        @include laptop {
            height: 16px;
        }
    }
}

.header-logo {
    --size: 40px;
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;

    @include laptop {
        --size: 32px;
    }

    svg {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;

        g,
        path {
            transition:
                fill $app-default-duration $app-default-easing,
                stroke $app-default-duration $app-default-easing;
        }
    }
}

.header-logo-rotor {
    .rotor-page & {
        svg path,
        g {
            &[fill] {
                fill: $primary;
            }
            &[stroke] {
                fill: $primary;
            }
        }
    }

    @include hover {
        .logo-rotormine-cog {
            transform: rotate(360deg);
        }

        .logo-rotormine-letters {
            transform: rotate(-360deg);
        }
    }

    &:active {
        .logo-rotormine-cog {
            transform: rotate(360deg);
        }

        .logo-rotormine-letters {
            transform: rotate(-360deg);
        }
    }

    &.header-logo-rotor--infinite {
        .logo-rotormine-cog {
            transition: none;
            animation: rotation-infinite 5s cubic-bezier(0.4, 1.3, 0.64, 1) infinite;
        }

        .logo-rotormine-letters {
            --rotation-sign: -1;
            transition: none;
            animation: rotation-infinite 5s cubic-bezier(0.4, 1.3, 0.64, 1) infinite;
        }

        @include hover {
            .logo-rotormine-cog {
                transform: none;
            }

            .logo-rotormine-letters {
                transform: none;
            }
        }
    }

    &.header-logo-rotor--animation {
        .logo-rotormine-cog {
            animation: rotation 2s cubic-bezier(0.4, 1.3, 0.64, 1);
        }

        .logo-rotormine-letters {
            --rotation-sign: -1;
            animation: rotation 2s cubic-bezier(0.4, 1.3, 0.64, 1);
        }
    }
}

.logo-rotormine-cog {
    &,
    .header-logo svg & {
        will-change: transform;
        transform-origin: 415px; // в некоторых браузерах только "px" работают корректно
        transition:
            transform 2s cubic-bezier(0.4, 1.3, 0.64, 1),
            fill $app-default-duration $app-default-easing,
            stroke $app-default-duration $app-default-easing;
    }
}

.logo-rotormine-letters {
    &,
    .header-logo svg & {
        will-change: transform;
        transform-origin: 415px; // в некоторых браузерах только "px" работают корректно
        transition:
            transform 2s cubic-bezier(0.4, 1.3, 0.64, 1),
            fill $app-default-duration $app-default-easing,
            stroke $app-default-duration $app-default-easing;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(calc(0deg * var(--rotation-sign, 1)));
    }

    100% {
        transform: rotate(calc(360deg * var(--rotation-sign, 1)));
    }
}

@keyframes rotation-infinite {
    0% {
        transform: rotate(calc(0deg * var(--rotation-sign, 1)));
    }

    39%,
    100% {
        transform: rotate(calc(360deg * var(--rotation-sign, 1)));
    }
}

.header-logo-jewerly {
    .jewerly-page & {
        svg path,
        g {
            &[fill] {
                fill: $primary;
            }
            &[stroke] {
                fill: $primary;
            }
        }
    }

    svg {
        overflow: visible;
    }

    @include hover {
        .logo-jewelry-i,
        .logo-jewelry-m {
            animation: letter-bounce 1.5s linear;
        }
    }

    &:active {
        .logo-jewelry-i,
        .logo-jewelry-m {
            animation: letter-bounce 1.5s linear;
        }
    }

    &.header-logo-jewerly--infinite {
        .logo-jewelry-i,
        .logo-jewelry-m {
            &:not(:hover),
            &:not(:active) {
                animation: letter-bounce-infinite 4s linear infinite;
            }
        }
    }

    &.header-logo-jewerly--animation {
        .logo-jewelry-i,
        .logo-jewelry-m {
            transition: none;
            animation: letter-bounce 1.5s linear;
        }
    }
}

.logo-jewelry-i {
    --letter-bounce-sign: -1;
}

@keyframes letter-bounce {
    0% {
        transform: translateX(0px);
    }

    30% {
        transform: translateX(calc(200px * var(--letter-bounce-sign, 1)));
    }

    38% {
        transform: translateX(calc(180px * var(--letter-bounce-sign, 1)));
    }

    47% {
        transform: translateX(calc(90px * var(--letter-bounce-sign, 1)));
    }

    55% {
        transform: translateX(calc(-10px * var(--letter-bounce-sign, 1)));
    }

    68% {
        transform: translateX(calc(50px * var(--letter-bounce-sign, 1)));
    }

    82% {
        transform: translateX(calc(-10px * var(--letter-bounce-sign, 1)));
    }

    87% {
        transform: translateX(calc(20px * var(--letter-bounce-sign, 1)));
    }

    94% {
        transform: translateX(-10px);
    }

    97% {
        transform: translateX(calc(10px * var(--letter-bounce-sign, 1)));
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes letter-bounce-infinite {
    0% {
        transform: translateX(0px);
    }

    10% {
        transform: translateX(calc(200px * var(--letter-bounce-sign, 1)));
    }

    13% {
        transform: translateX(calc(180px * var(--letter-bounce-sign, 1)));
    }

    16% {
        transform: translateX(calc(90px * var(--letter-bounce-sign, 1)));
    }

    19% {
        transform: translateX(calc(-10px * var(--letter-bounce-sign, 1)));
    }

    22% {
        transform: translateX(calc(50px * var(--letter-bounce-sign, 1)));
    }

    27% {
        transform: translateX(calc(-10px * var(--letter-bounce-sign, 1)));
    }

    30% {
        transform: translateX(calc(20px * var(--letter-bounce-sign, 1)));
    }

    33% {
        transform: translateX(-10px);
    }

    36% {
        transform: translateX(calc(10px * var(--letter-bounce-sign, 1)));
    }

    39%,
    100% {
        transform: translateX(0px);
    }
}
