.about-clock-section {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.about-page-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
}

.about-clock-section__content {
    --offset: 140px;
    padding-block: calc(var(--header-height) + var(--offset));
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;

    .no-scroll & {
        width: calc(100% - var(--scrollbar-width));

        @include tablet {
            width: 100%;
        }
    }

    @include vertical-tablet {
        --offset-top: 60px;
        position: relative;
        top: unset;
        left: unset;
        height: auto;
        align-items: center;
        text-align: center;
        padding: 0 var(--offset-x);
        padding-top: calc(var(--header-height) + var(--offset-top));
        width: 100%;
        gap: 52px;
    }

    @include mobile {
        --offset-top: 90px;
        gap: 48px;
    }
}

.about-clock-section__description {
    max-width: 333px;
    margin-block: auto;
}
