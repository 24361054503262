.web-share {
    position: relative;
}

.web-share-list {
    display: flex;
    justify-content: space-between;
    gap: 70px;
    align-items: center;
    padding: 35px 40px;
    background-color: $black-200;
    border-top: 2px solid $black;
}

.web-share-button {
    width: 20px;
    height: 20px;
    @include clickable(relative, 30px);
}

.web-share-top {
    padding: 35px 40px;
    background-color: $black-200;

    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
}

.web-share-inner {
    position: absolute;
    top: 0;
    right: 0;

    @include mobile {
        right: auto;
        left: 0;
    }
}

.web-share-title {
    opacity: 0.65;
    text-transform: uppercase;
}

.web-share-btn {
    --padding-y: 12px;
    --padding-x: 42px;

    @include laptop {
        --padding-y: 14px;
        --padding-x: 40px;
    }

    @include tablet {
        --padding-y: 10px;
        --padding-x: 40px;
    }

    @include mobile {
        --padding-y: 9px;
        --padding-x: 35px;
    }
}
