.review-card {
    --top-offset: 28px;
    --x-offset: 24px;
    position: relative;
    overflow: hidden;
    overflow: clip;
    width: 100%;
    height: calc(100% - var(--offset));
    transition: height 1.2s $app-default-easing;
    --offset: 144px;
    will-change: height;

    @include laptop {
        --offset: 104px;
    }

    @include tablet {
        --offset: 96px;
    }

    @include mobile {
        --offset: 0px;
    }
    .swiper-slide-active & {
        height: 100%;
    }

    &::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background-color: #000;
        opacity: 0.7;
        transition:
            top $app-default-easing 0.8s,
            height $app-default-easing 0.8s,
            opacity $app-default-easing $app-default-duration;

        @include mobile {
            opacity: 0.37;
        }
    }

    .swiper-slide-active &::after {
        opacity: 0.12;

        @include mobile {
            opacity: 0.37;
        }
    }

    &.review-card--idle::after {
        opacity: 0;
    }

    .swiper-slide:not(.swiper-slide-active) & {
        @include hover {
            &::after {
                opacity: 0.6;
            }
        }
    }
}

.review-card__type {
    margin-inline: 0;
    position: absolute;
    top: var(--top-offset);
    left: var(--x-offset);

    &.btn-visually-disabled {
        opacity: 1;
    }

    @include laptop {
        --padding-y: 6px;
    }

    @include mobile {
        --padding-y: 3px;
    }
}

.review-card__name {
    --text-offset: 18px;
    text-transform: uppercase;
    text-indent: calc(var(--offset, 0px) + var(--text-offset));
    max-width: min-content;
    line-height: 1.3;

    @include laptop {
        --text-offset: 6px;
    }
}

.review-card__description {
    margin-top: auto;
    transition: opacity 0.25s ease-in;

    .review-card--playing & {
        opacity: 0;
    }
}

.review-card__top {
    transition: opacity 0.25s ease-in;

    .review-card--playing & {
        opacity: 0;
    }
}

.review-card__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    padding: var(--top-offset) var(--x-offset) 72px var(--x-offset);
    display: flex;
    flex-direction: column;
    transition:
        top $app-default-easing 0.8s,
        height $app-default-easing 0.8s,
        opacity $app-default-easing 0.8s;
    opacity: 0;

    .swiper-slide-active & {
        opacity: 1;
    }

    @include mobile {
        display: none;
    }
}

.review-card__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    overflow: clip;
}

.review-card__image {
    transition: opacity 0.25s ease-in;

    .review-card--playing & {
        opacity: 0;
    }
    &,
    & img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.review-card__video {
    overflow: hidden;
    overflow: clip;
    transition: opacity 0.25s ease-in;

    .review-card:not(.review-card--playing) & {
        opacity: 0;
    }

    &,
    & video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.video-progress-bar {
    --offset: 5px;
    position: absolute;
    top: var(--offset);
    left: var(--offset);
    width: calc(100% - var(--offset) * 2);
    height: 4px;
    border-radius: 4px;
    background: rgba($white, 0.5);
    overflow: hidden;
}

.video-progress-bar__filled {
    height: 100%;
    background: #fff;
    transform-origin: left;
    transition: transform 0.25s linear;
}

.review-card__control {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.25s ease-in;
    margin-inline: 0;

    .review-card--idle & {
        opacity: 0;
    }
}

.review-slider-el__slide {
    flex-shrink: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &.swiper-slide-active,
    &.swiper-slide-duplicate-active {
        z-index: 1;

        .card {
            background-color: $black-200;
            transition-delay: 0.25s;

            &::before {
                transform: scale(1, 1.15);
                transition-delay: 0.25s;
            }
        }

        .card-header {
            transform: translate3d(0, -140px, 0);
            transition-delay: 0.25s;

            @include laptop {
                transform: translate3d(0, -80px, 0);
            }
        }

        .card-image {
            transform: translate3d(0, -70px, 0);
            transition-delay: 0.25s;

            @include laptop {
                transform: translate3d(0, -40px, 0);
            }
        }

        .card-footer,
        .card-favorite--index {
            opacity: 1;
            visibility: visible;
            transform: none;
            transition-delay: 0.25s;
        }
    }

    .card-header,
    .card-image {
        transition: transform 0.65s easeOutCubic;
    }

    .card.card-width-wide .card-header {
        align-items: flex-start;
    }

    .card-image {
        transform: translate3d(0, 100px, 0);

        @include laptop {
            transform: translate3d(0, 70px, 0);
        }
    }

    .card-name {
        transform-origin: 0% 0%;

        @media screen and (max-width: 767px) {
            transform-origin: 100% 0%;
        }
    }

    .card {
        overflow: visible;

        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: inherit;
            transform-origin: 0% 100%;
            transition: transform 0.65s easeOutCubic;
        }
    }

    .card-footer,
    .card-favorite--index {
        transition:
            opacity 0.3s ease,
            visibility 0.3s ease,
            transform 0.65s easeOutCubic;
        opacity: 0;
        visibility: hidden;
        transform: translate3d(0, 20px, 0);
    }

    &,
    &.swiper-slide {
        width: calc(100% / 4);
        max-width: 100%;

        @include tablet {
            width: calc(100% / 2);
        }

        @include vertical-large-tablet {
            width: calc(100% / 3);
        }

        @include horizontal-tablet {
            width: calc(100% / 3);
        }
    }
}

.reviews-list {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    gap: 2px;
    height: 100%;
}

.review-card__mobile {
    display: none;

    @include mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}

.show-review {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.show-review__icon {
    --size: 14px;
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
}

.show-review__text {
    font-size: 10px;
    font-weight: 500;
    font-family: $font-mono;
    letter-spacing: -0.03em;
    text-transform: uppercase;
}
