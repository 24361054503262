.dropdown {
    position: relative;
    display: flex;
    flex-direction: column;
}

.dropdown-toggler {
    cursor: pointer;
}

.dropdown-content {
    --offset: 0px;
    position: absolute;
    min-width: 100%;
    max-width: 100vw;

    &.bottom-left {
        top: calc(100% + var(--offset));
        left: 0;
    }

    &.bottom-center {
        top: calc(100% + var(--offset));
        left: 50%;

        .dropdown-content-inner {
            transform: translate(-50%, 0);
        }
    }

    &.bottom-right {
        top: calc(100% + var(--offset));
        right: 0;
    }

    &.top-left {
        bottom: calc(100% + var(--offset));
        left: 0;
    }

    &.top-right {
        bottom: calc(100% + var(--offset));
        right: 0;
    }

    @include tablet {
        margin-top: 40px;
    }

    @include mobile {
        margin-top: 26px;
    }
}

.input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
