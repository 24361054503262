.youtube-video {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
}

.youtube-video__preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.youtube-video__preview {
    background-color: $black-100;
    opacity: 1;
    transition: opacity 0.6s ease;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#000, 0.6);
        z-index: 1;
    }

    .youtube-video--playing & {
        opacity: 0;
        pointer-events: none;
    }
}

.youtube-video__player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    iframe {
        width: 100%;
        height: 100%;
    }
}

.youtube-video__button {
    position: relative;
    z-index: 2;
}
