.image-popup {
    visibility: hidden;
    transition: visibility 0s ease;
    transition-delay: 0.35s;
    z-index: 17;

    .popup-container {
        color: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        width: auto;

        @include tablet {
            width: 70%;
        }

        @include mobile {
            width: 85%;
        }
    }

    .popup-overlay {
        background-color: $black;
        opacity: 0.74;
    }

    &.popup--opened {
        visibility: visible;
        transition-delay: 0s;
    }

    .popup-container {
        @include hidden-scrollbar;
    }
}

.image-popup__inner {
    width: 100%;
}
