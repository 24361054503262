.pagination {
    display: flex;
    align-items: center;
    gap: 32px;
}

.pagination-btn {
    --size: 14px;
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    @include clickable(relative, 20px);

    @include hover {
        .pagination-btn__icon {
            transform: translateX(-12px);
        }
    }

    &.pagination-btn--next {
        transform: rotate(180deg);
    }

    svg {
        width: 100%;
        height: auto;
        max-height: 100%;
    }
}

.pagination-btn__icon {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: transform $app-default-duration $app-default-easing;
}

.pagination-number {
    --offset: 12px;
    --number-height: 18px;
    --hat-offset: 8px;
    position: relative;
    z-index: 1;
    min-width: 23px;
    height: calc(var(--number-height) + var(--offset) * 2 + var(--hat-offset) * 2);
    display: flex;
    flex-direction: column;
    gap: var(--offset);
    padding-block: var(--hat-offset);
}

.pagination-number__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.pagination-hat {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 23px;
    height: 4px;
    border: 1px solid $white;
    will-change: transform;

    &::before {
        position: absolute;
        content: '';
        width: 1px;
        height: 4px;
        left: 50%;
        transform: translateX(-50%);
        background-color: $white;
    }
}

.pagination-hat--top {
    border-bottom: none;
    top: 4px;

    &::before {
        bottom: 100%;
    }
}

.pagination-hat--bottom {
    border-top: none;
    bottom: 4px;

    &::before {
        top: 100%;
    }
}

.pagination-center {
    display: flex;
    align-items: center;
    padding-inline: 32px;
    position: relative;
    overflow: hidden;
    overflow: clip;
}

.pagination-line {
    height: 8px;
    width: 1px;
    background-color: rgba($white, 0.65);
    flex: none;
}

.pagination-lines {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    overflow: clip;
}

.pagination-lines--prev {
    left: -12px;
}

.pagination-lines--next {
    right: -12px;
}
