.reviews-slider__title {
    max-width: min-content;

    @include mobile {
        max-width: unset;
    }
}

.reviews-slider-el,
.review-card {
    .swiper-wrapper {
        display: flex;
        align-items: flex-end;
        will-change: transform;
    }

    .review-slider-el__slide {
        display: flex;
    }
}

.reviews-slider-el-wrapper {
    flex: 1 1 auto;

    @include mobile {
        height: 168px;
    }
}

.reviews-slider {
    height: calc(100vh - var(--app-filters-height) - var(--header-height));
    margin-top: 142px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow: clip;
    position: relative;

    @media screen and (max-width: 1366px) {
        margin-top: 130px;
    }

    @include tablet {
        height: calc(
            80vh - var(--app-filters-height) - var(--header-height) - var(--app-filters-offset) - var(--app-nav-height)
        );
    }

    @include horizontal-large-tablet {
        height: calc(70vh - var(--app-filters-height) - var(--header-height));
    }

    @include vertical-large-tablet {
        height: calc(60vh - var(--app-filters-height) - var(--header-height));
    }

    @include mobile {
        order: 6;
        margin-top: 116px;
        height: auto;
        margin-inline: calc(var(--offset-x) * -1);
    }
}

.reviews-slider__top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: flex-end;

    @include vertical-tablet {
        position: relative;
        margin-bottom: 56px;
    }

    @include mobile {
        align-items: center;
        flex-direction: column;
        padding-inline: var(--offset-x);
        text-align: center;
        margin-bottom: 24px;
    }

    @include horizontal-mobile {
        position: relative;
        flex-direction: row;
        justify-content: space-between;
    }
}
