.main-clock-section {
    min-height: 300vh;
}

.main-clock-section-inner {
    position: sticky;
    top: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.main-clock__canvas-el {
    width: 100%;
    height: 100%;
}

.main-clock__canvas-cog-wrapper {
    position: absolute;
    top: 54%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 209px;
}

.main-clock__canvas-cog {
    width: 100%;
    transform-origin: 50% 21%;
    will-change: transform;
}

.main-clock-section__canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow: clip;
}

.main-content {
    position: relative;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    &:not(.main-content--catalog-visible) {
        pointer-events: none;

        .main-content-second {
            .clip-container__el {
                transform: translate3d(0, -105%, 0);
            }
        }
    }

    &.main-content--catalog-visible {
        .main-content-first {
            .clip-container__el {
                transform: translate3d(0, -105%, 0);
            }
        }
    }
}

.main-content-first {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1365px) {
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        flex: 1 1 auto;
        margin-top: calc(var(--header-height) + 25px);
        margin-bottom: calc(var(--app-nav-height) - 15px);
    }
}

.main-content-item-1 {
    text-transform: uppercase;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 0px 12px;
    max-width: 100%;

    @supports (align-items: last baseline) {
        align-items: last baseline;
    }

    @media screen and (max-width: 1365px) {
        gap: 0px 9px;
        justify-content: flex-start;
    }
}

.main-content-item-1__text--last-word {
    width: 100%;
}

.main-content-item-1__text {
    line-height: 1;

    &:nth-child(1) {
        padding-bottom: 0.2em;
        padding-right: 30px;

        @include tablet {
            padding-right: 10px;
        }

        @supports (align-items: last baseline) {
            padding-bottom: 0;
        }
    }

    @include horizontal-large-tablet {
        font-size: 26px;
    }

    @include mobile {
        font-size: 22px;
    }
}

.main-content-item-2 {
    text-transform: uppercase;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 0px 19px;
    width: 40ch;
    max-width: 100%;
    margin-left: auto;
    text-align: right;

    @media screen and (max-width: 1680px) {
        column-gap: 15px;
    }

    @media screen and (max-width: 1440px) {
        column-gap: 10px;
    }

    @media screen and (max-width: 1366px) {
        width: 25ch;
    }

    @media screen and (max-width: 1365px) {
        gap: 0px 6px;
        margin-top: auto;
        padding-bottom: 40px;
    }

    @include tablet {
        padding-bottom: 65px;
    }

    @media screen and (max-width: 767px) {
        width: 20ch;
    }

    @include mobile {
        padding-bottom: var(--app-nav-height);
    }
}

.main-content-item-2__text {
    line-height: 1;

    @include horizontal-large-tablet {
        font-size: 26px;
    }

    @include mobile {
        font-size: 22px;
    }
}

.main-content-item-2__text-sm {
    max-width: 15ch;
    transform: translate(0, -50%);
}

.main-content-second {
    position: absolute;
    bottom: Min(14.8vh, 148px);
    left: calc(50% + var(--scrollbar-width, 0px));
    transform: translate(-50%, 0);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include vertical-tablet {
        bottom: Min(20vh, 170px);
    }

    @include mobile {
        bottom: auto;
        top: 64px;
    }
}

.main-content-second__title {
    &:not(:last-child) {
        margin-bottom: Min(5vh, 50px);

        @include vertical-tablet {
            margin-bottom: 22px;
        }

        @include mobile {
            margin-bottom: 22px;
        }
    }
}

.main-content-second__secondary {
    text-transform: uppercase;
    opacity: 0.6;

    @media screen and (max-width: 767px) {
        display: none;
    }
}

.main-clock-section__catalog-list__item {
    --mult: 1;
    --base-width: 12.5%;
    position: absolute;
    width: calc(var(--base-width) * var(--mult));

    @media screen and (max-width: 1200px) {
        --base-width: 140px;
    }

    @include mobile {
        --base-width: 108px;
    }

    @media screen and (max-width: 400px) {
        --base-width: 95px;
    }

    &.is-primary {
        @media screen and (min-width: 1200px) {
            --mult: 2;
        }

        @media screen and (max-width: 1199px) {
            --mult: 1.5;
        }
    }

    &:nth-child(1) {
        top: 50%;
        left: 15%;

        @include tablet {
            left: 3%;
        }

        @include mobile {
            top: 23%;
            left: 50%;
        }

        @include horizontal-mobile {
            top: 15%;
            left: 25%;
        }

        .catalog-round-item {
            transform: translate(0, -50%);

            @include mobile {
                transform: translate(-50%, 0);
            }
        }

        .catalog-round-item__content {
            padding: 120px;

            @media screen and (max-width: 1500px) {
                padding: 50px;
            }

            @include tablet {
                padding: 20px;
            }

            @include mobile {
                padding: 15px;
            }
        }
    }

    &:nth-child(2) {
        top: 20%;
        right: 30%;

        @include tablet {
            top: 30%;
            right: 15%;
        }

        @include mobile {
            top: auto;
            left: auto;
            bottom: 27%;
            right: 20%;
        }

        @include horizontal-mobile {
            bottom: 25%;
            right: 3%;
        }

        .catalog-round-item {
            transform: translate(0, -50%);

            @include mobile {
                transform: none;
            }
        }
    }

    &:nth-child(3) {
        top: 50%;
        right: 23%;

        @include tablet {
            right: 3%;
        }

        @include mobile {
            top: auto;
            right: auto;
            left: 35%;
            bottom: 27%;
        }

        @include horizontal-mobile {
            left: 75%;
            bottom: auto;
            top: 15%;
        }

        @media screen and (max-width: 420px) {
            bottom: 27%;
        }

        .catalog-round-item {
            transform: translate(0, -50%);

            @include mobile {
                transform: translate(-50%, 0);
            }
        }
    }

    &:nth-child(4) {
        top: 50%;
        right: 8%;

        @include tablet {
            top: 70%;
            right: 15%;
        }

        @include mobile {
            top: auto;
            right: auto;
            bottom: 38%;
            left: 3%;
        }

        @include horizontal-mobile {
            bottom: 25%;
        }

        .catalog-round-item {
            transform: translate(0, -50%);

            @include mobile {
                transform: none;
            }
        }
    }

    &:nth-child(5) {
        top: 20%;
        right: 15%;

        @include tablet {
            right: 40%;
        }

        @include mobile {
            top: auto;
            left: auto;
            bottom: 39%;
            right: 3%;
        }

        @include horizontal-mobile {
            bottom: 25%;
            right: 28%;
        }

        .catalog-round-item {
            transform: translate(0, -50%);

            @include mobile {
                transform: none;
            }
        }

        .catalog-round-item__media-lottie {
            svg {
                max-height: 90%;
            }
        }
    }
}
