.article-items-list {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    overflow: hidden;
    overflow: clip;
}

.article-items-list__item {
    flex: 1 1 50%;
    transition: flex-basis 1s ease;
    will-change: flex-basis;

    .article-item__inner {
        opacity: 0;
        transition: opacity 0.2s ease;
    }
}

.article-items-list__item--wide {
    .article-item__inner {
        opacity: 1;
        transition: opacity 0.5s 0.5s ease;
    }
    flex-basis: 100%;
}

.article-items-list__item--single {
    width: 50%;
    flex: none;

    .article-item__inner {
        opacity: 1;
        transition: opacity 0.5s 0.5s ease;
    }
}

.article-items-list-slider__item {
    .article-item__inner {
        opacity: 0;
        transition: opacity 0.5s ease;
    }

    &.swiper-slide-active {
        .article-item__inner {
            opacity: 1;
        }
    }
}
