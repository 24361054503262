.main-page-about {
    min-height: 100vmin;
    color: $white;
    background-color: $gray-brown;
    width: 100%;
}

.main-page-about-inner {
    position: sticky;
    top: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow: clip;
}

@mixin main-page-about-horizontal {
    @media screen and (max-width: 900px) and (orientation: portrait) {
        @content;
    }
}

.main-page-about__content {
    margin-top: auto;
    margin-bottom: auto;
    max-width: 375px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 1440px) {
        max-width: 233px;
    }

    @include main-page-about-horizontal {
        text-align: center;
        max-width: 310px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 200px;
        margin-bottom: 0;
    }

    @media screen and (max-width: 768px) and (orientation: portrait) {
        margin-top: 0;
        top: 18%;
    }

    @include small-mobile {
        top: 13%;
    }
}

.main-page-about__content-title {
    &:not(:last-child) {
        margin-bottom: 13.54vw;

        @media screen and (min-width: 1921px) {
            margin-bottom: 260px;
        }

        @include main-page-about-horizontal {
            margin-bottom: 28px;
        }

        @media screen and (max-width: 420px) {
            margin-bottom: 12px;
        }
    }

    & > span {
        width: 100%;
    }
}

.main-page-about__content-text {
    max-width: 310px;

    &:not(:last-child) {
        margin-bottom: 3.33vw;

        @media screen and (min-width: 1921px) {
            margin-bottom: 64px;
        }

        @include main-page-about-horizontal {
            margin-bottom: 44px;
        }

        @media screen and (max-width: 767px) {
            margin-bottom: 26px;
        }

        @media screen and (max-width: 420px) {
            margin-bottom: 10px;
        }
    }
}

.main-page-about__canvas-el {
    width: 100%;
    height: 100%;
}

.main-page-about__canvas-cog-wrapper {
    --cog-width: 209px;
    position: absolute;
    top: calc(50% + var(--cog-width) * 0.185);
    left: 100%;
    transform: translate(-50%, -50%);
    width: var(--cog-width);

    @include main-page-about-horizontal {
        left: 50%;
        top: 103.7%;
    }
}

.main-page-about__canvas-cog {
    width: 100%;
    transform-origin: 50% 21%;
    will-change: transform;
}

.main-page-about__canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow: clip;
}

.main-page-about-circle {
    --size: 376px;
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: var(--size);
    height: var(--size);
    text-align: center;
    display: flex;

    @include main-page-about-horizontal {
        top: 100%;
        left: 50%;
    }

    @media screen and (max-width: 576px) {
        --size: 200px !important;
    }
}

.main-page-about-circle-inner {
    width: 100%;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    border: 1px solid currentColor;
    padding-top: calc(0.201 * var(--size));
    padding-bottom: calc(0.3191 * var(--size));
    padding-left: calc(0.201 * var(--size));
    padding-right: calc(0.201 * var(--size));
    background-color: $gray-brown;
    color: $white;
    will-change: transform;

    @include tablet {
        padding-bottom: calc(0.201 * var(--size));
    }

    @include mobile {
        padding: calc(0.2 * var(--size)) calc(0.15 * var(--size));
    }
}

.main-page-about-circle__num {
    --size: 40px;
    border-radius: 50%;
    width: var(--size);
    height: var(--size);
    line-height: calc(var(--size) - 1px);
    border: 1px solid currentColor;
    margin-bottom: auto;

    @include mobile {
        --size: 18px;
    }
}

.main-page-about-circle__text {
    @media screen and (min-width: 1367px) {
        font-size: 17px;
    }
}

.main-page-about__content-link-wrapper {
    --padding-x: 20px;
    padding: 0 var(--padding-x);
    margin-left: calc(-0.5 * var(--padding-x));

    @include tablet {
        --padding-x: 15px;
    }
}
