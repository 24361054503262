.article-item {
    position: relative;
    display: flex;
}

.article-item-bg {
    @include absolute-picture(cover, absolute);
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    overflow: hidden;
    overflow: clip;

    &::after {
        position: absolute;
        content: '';
        background-color: #000;
        opacity: 0.25;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.article-item-img {
    opacity: 0.7;
}

.article-item__inner {
    position: relative;
    z-index: 1;
    padding: 38px;
    display: flex;
    flex-direction: column;
    gap: 28px;
    width: 100%;

    @include laptop {
        padding: 32px;
        gap: 24px;
    }

    @include tablet {
        padding: 28px;
    }

    @include mobile {
        padding: 20px;
    }
}

.article-item__header {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    width: 100%;

    @include small-laptop {
        flex-wrap: wrap;
    }
}

.article-item__tags {
    display: flex;
    gap: 10px 14px;
    flex-wrap: wrap;
    opacity: 0.6;
    max-width: 300px;
    white-space: nowrap;

    @include laptop {
        gap: 9px;
        max-width: 256px;
    }

    @include tablet {
        max-width: 220px;
    }

    @include mobile {
        gap: 8px;
        max-width: 160px;
    }
}

.article-item__info {
    display: flex;
    gap: 1ch;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.article-item__date {
    opacity: 0.6;
    white-space: nowrap;
}

.article-item__title {
    text-transform: none;
    max-width: 406px;
    line-height: 1;

    @include laptop {
        max-width: 300px;
    }

    @include tablet {
        max-width: 330px;
    }
}

.article-item__description {
    max-width: 340px;

    @include laptop {
        max-width: 320px;
    }

    @include tablet {
        max-width: 300px;
    }
}

.article-item__footer {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 28px;

    @include laptop {
        gap: 24px;
    }
}

.article-items-list-slider {
    overflow: hidden;
    max-width: 100%;

    .swiper-wrapper {
        display: flex;
    }
}

.article-items-list-slider__item {
    flex: none;
}
