.sort-popup {
    visibility: hidden;
    transition: visibility 0s ease;
    transition-delay: 0.5s;
    z-index: 17;

    .popup-container {
        --offset-y: 240px;
        color: $white;
        position: absolute;
        top: calc(var(--header-height) + var(--offset-y));
        left: var(--offset-x);
        height: auto;
        width: auto;

        @include laptop {
            --offset-y: 96px;
        }

        @include mobile {
            top: unset;
            left: 0;
            width: 100%;
            bottom: 0;
        }

        @include horizontal-mobile {
            overflow-y: auto;
        }
    }

    .popup-overlay {
        background-color: $black;
        opacity: 0.74;
    }

    &.popup--opened {
        visibility: visible;
        transition-delay: 0s;
    }

    .popup-container {
        overflow: hidden;
    }
}

.sort-popup__inner {
    position: relative;
    width: 470px;

    @include laptop {
        width: 372px;
    }

    @include mobile {
        width: 100%;
    }
}

.sort-popup__main {
    background-color: $black-200;
    padding: 40px 24px 40px 24px;
    position: relative;
    z-index: 1;

    @include laptop {
        padding: 36px 17px 36px 20px;
    }

    .catalog-popup-header {
        margin-bottom: 36px;

        @include laptop {
            margin-bottom: 26px;
        }

        @include tablet {
            flex-direction: row;
            margin-bottom: 48px;
        }

        @include mobile {
            flex-direction: column-reverse;
            gap: 25px;
        }

        @include horizontal-mobile {
            margin-bottom: 32px;
            gap: 18px;
        }
    }

    @include mobile {
        padding-bottom: calc(36px + 100lvh - 100dvh);
    }
}

.sort-popup__body {
    margin-left: 160px;
    display: flex;
    flex-direction: column;
    gap: 38px;

    @include laptop {
        margin-left: 104px;
        gap: 28px;
    }

    @include tablet {
        margin-left: 0;
        gap: 36px;
    }

    @include horizontal-mobile {
        gap: 28px;
        margin-left: 0;
    }
}
