.privacy-page {
    .page {
        --top-offset: 56px;
        padding-top: calc(var(--header-height) + var(--top-offset));

        @include laptop {
            --top-offset: 48px;
        }

        @include tablet {
            --top-offset: 38px;
        }

        @include mobile {
            --top-offset: 16px;
        }
    }

    .about-page-to-main {
        @include mobile {
            --top-offset: 16px;
        }
    }
}

.text-page {
    max-width: 936px;
    margin-inline: auto;

    h1 {
        margin-bottom: 80px;
    }

    h2 {
        margin-bottom: 48px;
        margin-top: 68px;
    }

    p {
        @extend .text-l;
    }
}
