.small-search-popup {
    visibility: hidden;
    transition: visibility 0s ease;
    transition-delay: 0.5s;
    z-index: 17;
    height: 100vh;
    height: 100dvh;

    .popup-container {
        --offset-y: 240px;
        color: $black;
        position: absolute;
        top: auto;
        bottom: var(--offset-x);
        left: 50%;
        transform: translateX(-50%);
        height: auto;
        width: auto;

        @include laptop {
            --offset-y: 96px;
        }

        @include tablet {
            bottom: 0;
        }
    }

    .popup-overlay {
        background-color: $black;
        opacity: 0.74;
    }

    &.popup--opened {
        visibility: visible;
        transition-delay: 0s;
    }

    .popup-container {
        overflow: hidden;
    }

    .catalog-popup-close {
        color: $black;
    }

    .input-block {
        @include hover {
            .input-block__input {
                border-color: $black;
            }
        }
    }

    .catalog-search {
        width: 100%;

        .input-block__input {
            @extend .text-l;

            border-bottom: 1px solid $black;

            &:focus {
                border-color: $black;
            }

            &:-webkit-autofill {
                border-color: rgba($black, 0.3);

                &:focus,
                &:active,
                &:hover {
                    border-color: $black;
                }
            }
        }

        .input-block__label {
            top: 0;
            opacity: 0.4;
        }

        svg path {
            stroke: $black;
        }

        .search-input-submit {
            --color-default: $black;

            .mustache {
                border: 1px solid $black;
            }

            .mustache-left {
                border-right-color: transparent;
            }

            .mustache-right {
                border-left-color: transparent;
            }
        }

        .search-input-buttons {
            top: 0;
            right: 12px;
            gap: 20px;

            .search-input-clear {
                transform: translateY(75%);

                @include mobile {
                    transform: translateY(0px);
                }
            }

            @include mobile {
                top: 50%;
            }
        }
    }
}

.small-search-popup__inner {
    position: relative;
    width: 30vw;

    @include laptop {
        width: 35vw;
    }

    @include tablet {
        width: 100vw;
    }

    // @include mobile {
    //     width: 100%;
    // }
}

.small-search-popup__main {
    background-color: $white;
    padding: 24px;
    position: relative;
    z-index: 1;

    .catalog-popup-header {
        margin-bottom: 100px;

        @include laptop {
            margin-bottom: 75px;
        }

        @include tablet {
            flex-direction: row;
        }

        @include mobile {
            gap: 25px;
            margin-bottom: 35px;
        }
    }

    @include mobile {
        padding: 20px 16px;
    }
}

.small-search-popup__body {
    display: flex;
    flex-direction: column;
    gap: 38px;

    @include laptop {
        gap: 28px;
    }

    @include tablet {
        gap: 36px;
    }

    @include horizontal-mobile {
        gap: 28px;
    }
}
