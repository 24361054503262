.advantages-section {
    min-height: 100vh;
    width: 100%;
    position: relative;
}

.advantages-section-canvas {
    position: relative;
    width: 100%;
    height: 100%;
}

.advantages-section-canvas-el {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.advantages-section__inner {
    --offset-top: 64px;
    padding-top: calc(var(--header-height) + var(--offset-top));
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 437px;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding-bottom: 104px;

    @include laptop {
        gap: 305px;
    }

    @include tablet {
        --offset-top: 80px;
    }

    @include mobile {
        --offset-top: 90px;
        gap: 211px;
    }
}

.advantages-list {
    --gap-y: 132px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: var(--gap-y) 0;

    @include laptop {
        --gap-y: 72px;
    }

    @include tablet {
        --gap-y: 60px;
    }

    @include mobile {
        flex-direction: column;
        gap: 222px;
    }
}

.advantages-title {
    width: min-content;
    text-align: center;
}

.advantages-list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: calc(100% / 3);
    transition:
        transform $app-default-easing 0.6s,
        opacity $app-default-easing 0.6s;
    transform: translateY(10px);
    opacity: 0;
    position: relative;

    &:nth-child(1) {
        width: auto;
        margin-left: 8%;

        @include tablet {
            width: 33.33%;
            margin-left: 0;
        }

        @include mobile {
            width: auto;
        }
    }

    &:nth-child(2) {
        width: auto;
        margin-left: auto;

        @include tablet {
            width: 33.33%;
            margin-left: 0;
        }

        @include mobile {
            width: auto;
        }
    }

    &:nth-child(3) {
        @include laptop {
            width: 28%;
        }

        @include tablet {
            width: 33.33%;
        }

        @include mobile {
            width: auto;
        }
    }

    &:last-child {
        margin-left: 100px;

        @include laptop {
            margin-left: 150px;
        }

        @include mobile {
            margin-left: 0;
        }
    }

    &.is-visible {
        opacity: 1;
        transform: translateY(0);
    }

    @include mobile {
        width: 100%;

        &:not(:first-child) {
            &::before {
                content: '';
                position: absolute;
                bottom: calc(100% + 24px);
                left: 50%;
                width: 1px;
                height: 174px;
                background-color: #fff;
                transition: transform $app-default-easing 0.6s;
                transform-origin: top;
                transform: scaleY(0);
            }
            &.is-visible {
                &::before {
                    transform: scaleY(1);
                }
            }
        }
    }

    &:nth-child(2) {
        margin-top: 48px;

        @include laptop {
            margin-top: 32px;
        }

        @include tablet {
            margin-top: 75px;
        }

        @include mobile {
            margin: 0;
        }
    }

    &:nth-child(3) {
        margin-top: 192px;

        @include laptop {
            margin-top: 137px;
        }

        @include tablet {
            margin-top: 166px;
        }
        @include mobile {
            margin: 0;
        }
    }

    &:nth-child(4) {
        order: 1;
        margin-inline: auto;

        @include tablet {
            order: unset;
            margin-left: 42px;
            margin-right: 0;
        }
        @include mobile {
            margin: 0;
        }
    }

    &:nth-child(5) {
        margin-top: 120px;
        margin-left: 78px;

        @include laptop {
            margin-top: 84px;
            margin-left: 48px;
        }

        @include tablet {
            margin-left: auto;
            margin-right: 58px;
        }
        @include mobile {
            margin: 0;
        }
    }
}

.advantages-list-item__text {
    max-width: 273px;
    text-align: center;

    @include laptop {
        max-width: 255px;
    }

    @include tablet {
        max-width: 186px;
    }

    @include mobile {
        max-width: 83%;
    }
}
