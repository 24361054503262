.search-popup {
    visibility: hidden;
    transition: visibility 0s ease;
    transition-delay: 0.5s;
    z-index: 17;

    .popup-container {
        color: $white;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: inherit;
    }

    .popup-overlay {
        background-color: $black;
        opacity: 0.74;
    }

    &.popup--opened {
        visibility: visible;
        transition-delay: 0s;
    }

    .popup-container {
        overflow: hidden;
    }
}

.search-popup__inner {
    position: relative;
    width: 100%;
    height: 100%;
}

.search-popup__main {
    background-color: $black-200;
    height: 100%;
    padding: 48px var(--offset-x) 40px var(--offset-x);
    position: relative;
    z-index: 1;

    .catalog-popup-header {
        flex-direction: row;
        margin-bottom: 168px;
    }
}

.popup-search {
    .search-input {
        @extend .h2;
        font-weight: 400;
    }
}
