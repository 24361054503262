.card-thumbs {
    width: 50px;
    min-height: 22px;
    max-height: 22px;
    margin-bottom: 25px;
    overflow: hidden;

    @include laptop {
        width: 35px;
        margin-bottom: 15px;
    }

    @include mobile {
        margin-bottom: 8px;
    }

    .swiper-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .swiper-slide-thumb-active {
        .card-thumbs__line {
            opacity: 1;
            height: 22px;

            @include mobile {
                height: 12px;
            }
        }
    }

    .card-thumbs__slide {
        display: flex;
        align-items: center;
    }

    .card-thumbs__line {
        display: flex;
        align-items: center;
        height: 8px;
        width: 1px;
        background: $white;
        opacity: 0.65;
        transition:
            opacity $app-default-duration ease,
            height $app-default-duration ease;

        @include clickable(relative, 10px);

        @include mobile {
            height: 6px;
        }
    }
}

.card-thumbs.is-center {
    .swiper-wrapper {
        justify-content: center;
    }
}
