.catalog-round-item {
    --border-radius: 50%;
    display: block;
    border-radius: var(--border-radius);
    position: relative;
    z-index: 1;
    outline-offset: 0;

    &:focus-visible {
        &::before {
            outline: 1px solid #fff;
            outline-offset: 4px;
        }
    }

    &::before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        background-color: $black;
        border-radius: var(--border-radius);
        border: 1px solid #fff;
        transition: transform 0.65s $app-default-easing;
    }

    &.is-primary {
        &::after {
            content: '';
            position: absolute;
            z-index: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% + 2px + 40px);
            height: calc(100% + 2px + 40px);
            background-color: $black;
            border-radius: var(--border-radius);
            border: 0.5px solid #fff;
            transition: transform 0.65s $app-default-easing;
            display: none;

            @media (any-hover: hover), (hover: hover) and (pointer: fine) {
                display: block;
            }
        }
    }

    &.is-hovered,
    &:active {
        &::before,
        &::after {
            transform: translate(-50%, -50%) scale(1.05);
        }

        .catalog-round-item__content-inner {
            @media (any-hover: hover), (hover: hover) and (pointer: fine) {
                transform: translate(0, calc(-15px * var(--mult, 1)));
            }
        }

        .catalog-round-item__media-lottie {
            transform: translate(0, calc(-8px * var(--mult, 1)));
        }

        .catalog-round-item__amount {
            opacity: 0.6;
        }
    }
}

.catalog-round-item__responsive {
    --aspect-ratio: 1;
    z-index: 2;
}

.catalog-round-item__content {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: flex-end;
    padding: 38px;
    text-transform: uppercase;

    @include laptop {
        padding: 24px;
    }

    @include tablet {
        padding: 20px;
        font-size: 10px;
    }

    @include mobile {
        padding: 15px;
        font-size: 8px;
    }
}

.catalog-round-item__content-inner {
    transition: transform 0.4s $app-default-easing;
}

.catalog-round-item__amount {
    text-align: center;
    opacity: 0.6;
    margin-top: 12px;
    transition: opacity 0.4s $app-default-easing;

    @media screen and (max-width: 1366px) {
        margin-top: 10px;
    }

    @media (any-hover: hover), (hover: hover) and (pointer: fine) {
        opacity: 0;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        pointer-events: none;
    }

    @include horizontal-large-tablet {
        display: none;
    }

    @include tablet {
        display: none;
    }
}

.catalog-round-item__media {
    border-radius: var(--border-radius);
    overflow: hidden;
    overflow: clip;
}

.catalog-round-item__media-lottie-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius);
}

.catalog-round-item__media-lottie {
    width: 70%;
    max-width: 192px;
    margin: auto;
    transition: transform 0.4s $app-default-easing;
}
