.article-product-list {
    display: flex;
    justify-content: center;
    margin-inline: auto;
    width: 100%;
    --gap: 2px;
    gap: var(--gap);
}

.article-product-item {
    display: flex;

    .card {
        width: 100%;
    }
}

.article-product-item--single {
    --koef: 2.05;
    width: calc(100vw / var(--koef));

    @include tablet {
        width: calc(100% - var(--offset-x) * 2);
    }

    @include mobile {
        width: 100%;
    }
}

.article-product-item--duo {
    width: calc(50% - var(--offset-x) - var(--gap) / 2);
}

.article-product-list-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;

    .swiper {
        width: 100%;
    }

    @include laptop {
        gap: 52px;
    }

    @include tablet {
        gap: 44px;
    }

    @include mobile {
        gap: 28px;
    }
}
