.checkbox {
    padding-left: 32px;
    position: relative;
    cursor: pointer;

    input[type='checkbox'],
    input[type='radio'] {
        @extend .visually-hidden;

        &:checked {
            ~ .checkbox__element {
                &::before {
                    opacity: 1;
                }
            }
        }

        &:disabled {
            ~ .checkbox__element,
            ~ .checkbox__text {
                opacity: 0.6;
            }
        }

        &:focus-visible {
            ~ .checkbox__element {
                outline: 1px solid currentColor;
                outline-offset: 2px;
            }
        }

        &.is-error {
            ~ .checkbox__element {
                border-width: 1px;
                border-color: $danger;
            }
        }
    }

    @include hover {
        .checkbox__just-text {
            opacity: 0.7;
        }
    }
}

.checkbox__just-text {
    transition: opacity $app-default-duration $app-default-easing;
    opacity: 0.3;
}

.checkbox__element {
    --size: 20px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: var(--size);
    height: var(--size);
    border: 1px solid rgba($white, 0.2);
    will-change: transform;

    input[type='radio'] ~ & {
        border-radius: 50%;

        &::before {
            border-radius: 50%;
            width: 8px;
            height: 8px;
            background-color: currentColor;
            background-image: none;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        border-radius: inherit;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 6.26667L4.18182 10L11 2' stroke='%23F4F4F4'/%3E%3C/svg%3E%0A");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0;
        transition: opacity 0.1s ease;
    }
}
