.btn {
    --bg-color-default: transparent;
    --bg-color-hover: var(--bg-color-default);
    --bg-color-active: var(--bg-color-hover);
    --bg-color-disabled: var(--bg-color-default);
    --bg-color: var(--bg-color-default);

    --border-color-default: transparent;
    --border-color-hover: var(--border-color-default);
    --border-color-active: var(--border-color-hover);
    --border-color-disabled: var(--border-color-default);
    --border-color: var(--border-color-default);

    --color-default: #{$body-color};
    --color-hover: var(--color-default);
    --color-active: var(--color-hover);
    --color-disabled: var(--color-default);
    --color: var(--color-default);

    --outline-color: currentColor;

    --padding-x: 0px;
    --padding-y: 0px;

    --line-height: 1;

    display: inline-block;
    text-align: center;
    color: var(--color);
    background-color: var(--bg-color);
    border: 1px solid var(--border-color);
    border-radius: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: var(--line-height);
    min-height: calc(var(--line-height) * 1em + var(--padding-y) * 2);
    position: relative;
    padding: 0;
    letter-spacing: 0;
    cursor: pointer;
    text-decoration: none;
    flex-shrink: 0;
    transition:
        color 0.2s ease,
        border-color 0.2s ease,
        background-color 0.2s ease;

    &:not(.btn-geometry-large) {
        .btn__outer {
            overflow: hidden;
            overflow: clip;
            display: flex;
        }
    }

    @media (any-hover: hover), (hover: hover) and (pointer: fine) {
        &:not(:disabled):hover:not(.btn-geometry-large):not(.btn-visually-disabled):not([disabled]):not(.btn-share) {
            --color: var(--color-hover);
            --bg-color: var(--bg-color-hover);
            --border-color: var(--border-color-hover);

            .btn__inner {
                transform: translateY(-110%);
            }

            .btn__copy {
                transform: translateY(0);
            }
        }

        &[disabled] {
            cursor: default;
        }
    }

    &:hover,
    &:active {
        color: var(--color);
    }

    &:not(:disabled):active {
        --color: var(--color-active);
        --bg-color: var(--bg-color-active);
        --border-color: var(--border-color-active);
    }

    &:focus-visible {
        --color: var(--color-hover);
        --bg-color: var(--bg-color-hover);
        --border-color: var(--border-color-hover);
        outline: 2px solid var(--outline-color);
        outline-offset: 4px;
    }

    &:disabled,
    &.btn-visually-disabled {
        --color: var(--color-disabled);
        --bg-color: var(--bg-color-disabled);
        --border-color: var(--border-color-disabled);
        cursor: not-allowed;
        opacity: 0.6;
    }

    &.m-text-xs {
        @extend .m-text-xs;
    }
}

.btn__outer {
    position: relative;
}

.btn__inner,
.btn__copy {
    padding: var(--padding-y) var(--padding-x);
    transition: transform $app-default-easing $app-default-duration;
    will-change: transform;
    display: flex;
    align-items: center;
    gap: 6px;

    .btn-icon-reverse & {
        flex-direction: row-reverse;
    }
}

.btn__icon {
    --size: 14px;
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    svg {
        width: 100%;
        height: 100%;
    }
}

.btn__icon-arrow-not-reversed {
    margin-left: 9px;
    transform: translateX(-3px);
}

.btn__icon-arrow-reversed {
    margin-right: 9px;
    transform: translateX(3px);
}

.btn__icon-arrow {
    transition: transform $app-default-duration $app-default-easing;
}

.btn-geometry-arrow {
    @include hover {
        .btn__icon-arrow-not-reversed {
            transform: translateX(0);
        }
        .btn__icon-arrow-reversed {
            transform: translateX(0);
        }
    }
}

.btn__copy {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(110%);
}

.btn__text {
    @extend .text-xs;
    text-transform: uppercase;
}

.btn.m-text-xs .btn__text {
    @extend .m-text-xs;
}

.btn-primary {
    --color-default: #{$black};
    --bg-color-default: #{$white};
    border: none;

    --padding-x: 15px;
    --padding-y: 15px;

    &.btn-sm {
        --padding-y: 11px;
    }

    @include laptop {
        --padding-y: 12px;

        &.btn-sm {
            --padding-y: 10px;
        }
    }

    @include tablet {
        &.btn-sm {
            --padding-y: 6px;
        }
    }
}

.btn-bordered-light {
    --color-default: #{$white};
    --bg-color-default: transparent;
    --border-color-default: rgba(244, 244, 244, 0.4);
    --padding-x: 15px;
    --padding-y: 15px;

    @include laptop {
        --padding-y: 12px;
    }
}

.btn-bordered-light-bg-dark {
    --color-default: #{$white};
    --bg-color-default: #{$black};
    --border-color-default: rgba(244, 244, 244, 0.4);
    --padding-x: 15px;
    --padding-y: 15px;

    @include laptop {
        --padding-y: 12px;
    }
}

.btn-dark {
    --color-default: #{$white};
    --bg-color-default: #{$black};
    --border-color-default: #{$black};

    --padding-x: 15px;
    --padding-y: 15px;
}

.btn-geometry-wide {
    width: 100%;
}

.btn-geometry-mustache {
    display: inline-flex;
    align-items: center;
    --to-mustache-offset: 12px;
    --padding-x: calc(var(--to-mustache-offset) + var(--mustache-width) + var(--mustache-offset));
    --padding-y: 9px;
    --color-default: #{$white};
    --mustache-main-width: 5px;
    --mustache-before-width: 3px;
    --mustache-width: calc(var(--mustache-main-width) + var(--mustache-before-width));
    --mustache-offset: 8px;
    margin-inline: calc(var(--to-mustache-offset) * -1);

    &:disabled,
    &.btn-visually-disabled {
        pointer-events: none;
    }

    &.btn-sm {
        --mustache-offset: 0px;
        --to-mustache-offset: 8px;
        --padding-y: 7px;

        @include mobile {
            --padding-y: 0px;
        }
    }

    @include hover {
        .mustache-left {
            transform: translateX(calc(var(--mustache-offset) * -1));
        }
        .mustache-right {
            transform: translateX(var(--mustache-offset));
        }
    }

    @include mobile {
        --padding-y: 4px;
    }
}

.btn.btn-geometry-square-bracket {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    @extend .text-xs;

    .btn__text {
        transform: translateY(0.1em);
    }

    @include hover {
        .square-bracket--left {
            transform: translateX(-3px);
        }
        .square-bracket--right {
            transform: translateX(3px);
        }
    }
}

.square-bracket {
    transition: transform $app-default-duration $app-default-easing;
    opacity: 0.5;
}

.mustache {
    width: var(--mustache-main-width);
    position: absolute;
    top: 0;
    height: 100%;
    border: 1px solid $white;
    transition: transform $app-default-duration $app-default-easing;

    &::before {
        position: absolute;
        content: '';
        top: 50%; // да, не совсем по центру, но по центру попадает между пикселями и выглядит плохо
        width: var(--mustache-before-width);
        height: 1px;
        background-color: $white;
    }
}

.mustache-left {
    left: 0;
    border-right-color: transparent;
    margin-left: var(--mustache-before-width);
    &::before {
        right: 100%;
    }
}

.mustache-right {
    right: 0;
    border-left-color: transparent;
    margin-right: var(--mustache-before-width);
    &::before {
        left: 100%;
    }
}

.btn-geometry-large {
    --padding-x: 12px;
    --padding-y: 16px;

    &.btn-sm {
        --padding-x: 8px;
        --padding-y: 10px;
    }

    &:not(.btn-sm) {
        .btn__text {
            @extend .h1;
        }
    }

    .btn__text.h2 {
        @extend .h2;
    }

    .btn__text.contacts-socnet {
        @extend .contacts-socnet;
    }

    .btn__copy {
        display: none;
    }

    &.btn-sm {
        --padding-y: 10px;
    }

    @include hover {
        .hat {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @include active {
        --offset: 2px;
        &.btn-sm {
            --offset: 1px;
        }
        .hat-top {
            opacity: 1;
            transform: translateY(var(--offset));
        }
        .hat-bottom {
            opacity: 1;
            transform: translateY(calc(var(--offset) * -1));
        }
    }
    @include laptop {
        --padding-y: 8px;
        --padding-x: 6px;

        &.btn-sm {
            --padding-y: 4px;
        }
    }

    @include mobile {
        &.btn-sm {
            --padding-y: 0px;
        }
    }
}

.hat {
    position: absolute;
    left: 0;
    width: 100%;
    height: 6px;
    border: 1px solid $white;
    opacity: 0;
    transition:
        opacity $app-default-duration $app-default-easing,
        transform $app-default-duration $app-default-easing;
}

.hat-top {
    top: 0;
    border-bottom-color: transparent;
    transform: translateY(-4px);
}

.hat-bottom {
    bottom: 0;
    border-top-color: transparent;
    transform: translateY(4px);
}
