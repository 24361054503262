:root {
    // Боковые оффсеты страницы
    --offset-x: 24px;

    // Сетка
    --grid-columns: 24;
    --grid-gap: 0px;
    --grid-column-width: calc(
        (100vw - var(--scrollbar-width, 0px) - var(--offset-x) * 2) / var(--grid-columns) -
            (var(--grid-gap) * ((var(--grid-columns) - 1) / var(--grid-columns)))
    );

    --header-offset: 24px;
    --header-content: 64px;

    // Высота хедера
    --header-height: calc(var(--header-content) + var(--header-offset) * 2);
    --app-nav-height: 48px;
    --app-nav-small-height: 48px;
    --app-filters-height: 42px;
    --app-filters-offset: 16px;
    --app-nav-catalog-button-width: 300px;

    @include large-desktop {
        --app-nav-catalog-button-width: 16.25vw;
    }

    @include laptop {
        --header-content: 62px;
        --header-offset: 20px;
        --offset-x: 20px;
        --app-nav-height: 48px;
        --app-nav-small-height: 40px;
        --app-filters-offset: 12px;
        --app-filters-height: 40px;
        --app-nav-catalog-button-width: 250px;
    }

    @media screen and (max-width: 1579px) {
        --app-nav-catalog-button-width: 190px;
    }

    @media screen and (max-width: 1399px) {
        --app-nav-catalog-button-width: 160px;
    }

    @include tablet {
        --header-content: 52px;
        --app-nav-height: 48px;
        --header-height: var(--header-content);
        --header-offset: 10px;
    }

    @include mobile {
        --offset-x: 16px;
        --app-filters-offset: 8px;
        --app-nav-height: 42px;
    }
}
