.error-page {
    .footer {
        display: none;
    }
    .app-nav-link--catalog {
        display: none;
    }
    .app-nav-burger {
        margin-inline: auto;
    }
    &.no-scroll .header {
        padding-right: var(--offset-x);
    }
}

.error-page-layout {
    min-height: 100vh;
}

.error-page-content {
    --bottom-offset: 160px;
    position: absolute;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(var(--app-nav-height) + var(--bottom-offset));
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 48px;

    @include large-desktop {
        --bottom-offset: 8.33vw;
    }

    @include laptop {
        --bottom-offset: 98px;
        gap: 40px;
    }

    @include vertical-tablet {
        --bottom-offset: 192px;
        gap: 36px;
    }

    @include mobile {
        --bottom-offset: 70px;
        gap: 34px;
    }
}

.error-page-content__text {
    max-width: 225px;
}
