.filters-popup {
    visibility: hidden;
    transition: visibility 0s ease;
    transition-delay: 0.5s;
    z-index: 17;
    height: 100lvh;

    .popup-container {
        color: $white;
        position: absolute;
        top: var(--header-height);
        left: var(--offset-x);
        height: auto;
        width: auto;

        @include tablet {
            width: 100%;
            height: inherit;
            top: 0;
            left: 0;
        }
    }
    .popup-overlay {
        background-color: $black;
        opacity: 0.74;
    }

    &.popup--opened {
        visibility: visible;
        transition-delay: 0s;
    }

    .popup-container {
        @include hidden-scrollbar;
    }
}

.filters-popup__inner {
    --main-width: 720px;
    position: relative;
    min-width: var(--main-width);
    width: var(--width, var(--main-width, 720px));
    box-sizing: content-box;
    max-height: calc(100vh - var(--header-height) * 1.5);
    overflow: hidden;
    transition: padding-right 0.6s ease;
    will-change: padding-right;

    .all-filters--opened & {
        padding-right: var(--all-filters-width, 0px);
        @include tablet {
            padding-right: 0;
        }
    }

    @include tablet {
        --main-width: 576px;
        width: 100%;
        height: 100%;
        min-width: unset;
        max-height: unset;
    }
}

.filters-popup__main {
    background-color: $black-200;
    padding: 40px 24px 116px 24px;
    position: relative;
    z-index: 1;
    border-right: 1px solid $black;
    max-width: var(--main-width);

    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - var(--header-height) * 1.5);
    -webkit-overflow-scrolling: touch;

    @include thick-scrollbar;

    @include laptop {
        padding: 36px 20px 108px;
    }

    @include tablet {
        padding: 24px var(--offset-x) 100px var(--offset-x);
        width: 100%;
        height: 100%;
        max-width: unset;
        padding-bottom: calc(100px + 100lvh - 100dvh);
    }
}

.filters-body {
    display: flex;
    flex-direction: column;

    @include tablet {
        max-width: 83%;
    }

    @include mobile {
        max-width: 100%;
    }
}

.filter-row {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &:not(:last-child) {
        padding-bottom: var(--gap);
        border-bottom: 1px solid rgba($gray, 0.3);
    }
}

.filter-row__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
}

.filter-row__name {
    width: var(--names-width, auto);
    flex: none;
    opacity: 0.6;

    @include tablet {
        @include responsive-font-size(14px, 16px, 360px, 1024px);
    }
}

.all-brands {
    position: absolute;
    right: 0;
    top: 0;
    background-color: $black-200;
    height: 100%;
    padding: 40px 20px 80px 24px;
    min-width: 234px;
    display: flex;
    flex-direction: column;
    gap: 36px;

    @include laptop {
        padding: 36px 17px 0px 20px;
        gap: 28px;
        min-width: 186px;
    }

    @include tablet {
        width: 100%;
        height: 100vh;
        height: 100svh;
        z-index: 1;
    }
}

.all-brands-close {
    align-self: flex-end;
}

.all-brands-list {
    display: flex;
    flex-direction: column;
    gap: 28px;
    @include thick-scrollbar;
    overflow-y: auto;
    padding-bottom: 24px;

    @include laptop {
        gap: 24px;
    }

    @include tablet {
        gap: 32px;
        width: 355px;
        margin-left: 270px;
        padding-bottom: 175px;
    }

    @include mobile {
        width: 100%;
        margin-left: 0;
        padding-bottom: 150px;
    }
}

.all-brands-search {
    display: none;

    @include tablet {
        display: flex;
        width: 355px;
        margin-left: 270px;

        .input-block {
            width: 100%;
        }
    }

    @include mobile {
        width: 100%;
        margin-left: 0;
    }
}

.all-brands-search__input .input-block__input {
    text-transform: uppercase;
}

.all-brands-button {
    --padding-x: 16px;
    --padding-y: 0px;
    --mustache-offset: 2px;
    width: max-content;
    margin-left: 0;

    .btn__icon {
        transition: transform $app-default-easing $app-default-duration;
    }

    @include tablet {
        &.btn .btn__text {
            @include tablet {
                @include responsive-font-size(14px, 16px, 360px, 1024px);
            }
        }
    }

    &.is-open {
        .btn__icon {
            transform: translateY(0.1em) rotate(30deg);
        }
    }
}

.all-brands-button-brand {
    align-items: flex-start;
    gap: 10px;
    display: none;

    @include tablet {
        display: flex;
    }
}

.all-brands-button-brand__text {
    @include responsive-font-size(14px, 20px, 360px, 768px);
    letter-spacing: -0.03em;
    text-transform: uppercase;
    line-height: 1.2;

    @include mobile {
        line-height: 27px;
    }
}

.catalog-popup-close.btn {
    .btn__text,
    &.btn-geometry-square-bracket {
        @include tablet {
            @include responsive-font-size(14px, 20px, 360px, 1024px);
        }
    }
}

.all-materials-list {
    padding-right: 5px;
}

.filters-sizes-item-buttons-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.filters-sizes-item-button {
    flex: 1 1 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 24px;
    border-right: 1px solid rgba($gray, 0.15);
    height: 144px;
    transition: background-color $app-default-duration ease;

    @media screen and (max-width: 767px) {
        flex: 0 0 50%;
        border-bottom: 1px solid rgba($gray, 0.15);

        &:nth-child(2n) {
            border-right-color: transparent;
        }

        &:last-child {
            border-bottom-color: transparent;
        }
    }

    @include hover {
        background-color: rgba($white, 0.04);
    }

    &.is-active {
        background-color: rgba($white, 0.04);
    }
}

.filters-sizes-item-button__img {
    width: 50%;
    height: 33.3%;
    object-fit: contain;

    @include mobile {
        height: 28%;
    }
}

.filters-sizes-item-button__text {
    text-transform: uppercase;
}
