.search-page {
    @include mobile {
        .catalog-find-product {
            margin-top: 0;
        }

        .catalog-top__right {
            order: 1;
            margin-top: 46px;
        }
    }

    .catalog-page-title {
        padding-right: 56px;

        @include mobile {
            padding-right: 90px;
        }
    }

    .card-list-title {
        display: none;

        @include tablet {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding-bottom: 48px;
            width: 100%;
            padding-inline: var(--offset-x);
        }

        @include mobile {
            order: 1;
        }
    }

    .mobile-filters {
        @include tablet {
            width: 100%;
            margin-top: 36px;
            margin-bottom: 0;
        }
    }
}

.catalog-search-desk {
    @include tablet {
        display: none;
    }

    @include mobile {
        display: block;
    }
}

.catalog-search-tablet {
    position: absolute;
    top: var(--text-top);
    display: none;
    width: 45%;

    @include tablet {
        display: block;
    }

    @include mobile {
        display: none;
    }
}
