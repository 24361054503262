h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h5,
.h5 {
    font-weight: 400;
}

body {
    font-family: $font-family;
}

h1,
.h1 {
    @include responsive-font-size(36px, 48px, 1280px, 1920px);
    line-height: 1;
    letter-spacing: -0.05em;
    text-transform: uppercase;

    @include laptop {
        line-height: 1.11;
    }

    @include tablet {
        @include responsive-font-size(28px, 32px, 360px, 768px);
        line-height: 1.25;
    }

    @include mobile {
        line-height: 1.07;
    }
}

h2,
.h2 {
    @include responsive-font-size(24px, 32px, 1280px, 1920px);
    letter-spacing: -0.05em;
    line-height: 1.125;

    @include laptop {
        line-height: 1.16;
    }

    @include tablet {
        @include responsive-font-size(20px, 22px, 360px, 768px);
        line-height: 1.09;
    }

    @include mobile {
        line-height: 1.1;
    }
}

.text-l {
    @include responsive-font-size(15px, 17px, 1280px, 1920px);
    line-height: 1.17;
    letter-spacing: -0.03em;

    @include laptop {
        line-height: 1.13;
    }

    @include tablet {
        @include responsive-font-size(13px, 14px, 360px, 768px);
        line-height: 1.21;
    }

    @include mobile {
        line-height: 1.3;
    }
}

.text-s {
    @include responsive-font-size(14px, 15px, 1280px, 1920px);
    line-height: 1.2;
    letter-spacing: -0.03em;

    @include laptop {
        line-height: 1.14;
    }

    @include tablet {
        @include responsive-font-size(11px, 12px, 360px, 768px);
        line-height: 1.16;
    }

    @include mobile {
        line-height: 1.09;
    }
}

.text-xs {
    @include responsive-font-size(11px, 14px, 1280px, 1920px);
    line-height: 1.14;
    letter-spacing: -0.03em;
    font-weight: 500;

    @include laptop {
        line-height: 1.27;
    }

    @include mobile {
        font-size: 10px;
        line-height: 1.2;
    }
}

.m-text-xs {
    @include responsive-font-size(13px, 14px, 1280px, 1920px);
    line-height: 1.28;
    letter-spacing: -0.03em;
    font-weight: 500;
    font-family: $font-mono;
    text-transform: uppercase;

    @include laptop {
        line-height: 1.07;
    }

    @include tablet {
        font-size: 11px;
        line-height: 1.18;
    }

    @include mobile {
        font-size: 10px;
        line-height: 1.3;
    }
}
