.catalog-category-card-lottie {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
    transform: translateY(0);
    transition:
        opacity 0.6s $app-default-easing,
        transform 0.6s $app-default-easing;
}

.catalog-category-card {
    width: calc(var(--grid-column-width) * 3 - var(--gap));
    padding: 50px 47px 54px;
    background-color: $black-100;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: background-color $app-default-duration $app-default-easing;

    .catalog-top__right--short-list & {
        &:not(.catalog-category-card--main) {
            width: calc(var(--grid-column-width) * 6 - var(--gap));
            padding-inline: 140px;

            @include laptop {
                padding-inline: 85px;
            }

            @include tablet {
                padding: 34px 22px;
            }

            @include mobile {
                width: calc(50% - var(--gap) / 2);
                flex: none;
                padding: 24px;
            }
        }
    }

    @include hover {
        background-color: $black-200;
    }

    @include laptop {
        padding: 26px 24px 30px 24px;
    }

    @include tablet {
        width: auto;
        flex: 1 1 100%;
        padding: 34px 22px;
    }

    @include mobile {
        padding: 24px;
        background-color: transparent;
        position: relative;
        width: calc(
            50% - var(--gap) / 2
        ) !important; // Перебиваю кучу условий и доп классов, нужных, чтобы красиво смотрелось при разном количестве наполненных категорий, а на мобилке отображение всегда такое
        flex: none;
        flex-direction: row;
        gap: 20px;

        &::before {
            position: absolute;
            content: '';
            background-color: $black-100;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition:
                background-color $app-default-duration $app-default-easing,
                transform 0.6s $app-default-easing;
            transform-origin: bottom;
        }
    }
}

.catalog-category-card--main {
    width: calc(var(--grid-column-width) * 6 - var(--gap));
    padding: 96px 88px;
    background-color: $black-200;
    flex: none;

    @include laptop {
        padding: 68px 54px 72px 54px;
    }

    @include tablet {
        width: auto;
        flex: 1 1 100%;
        padding: 34px 22px;

        .catalog-top__right--single & {
            width: 50%;
            flex: none;
        }
    }

    @include mobile {
        padding: 24px;
        flex: none;
        width: calc(50% - var(--gap) / 2);

        &::before {
            background-color: $black-200;
        }
    }
}

.swiper-slide-active .catalog-category-card,
.swiper-slide-duplicate-active .catalog-category-card {
    &::before {
        background-color: $black-200;
        transform: scaleY(1.25);
    }
}

.catalog-category-amount {
    opacity: 0.6;
    transform: translateY(0);
    margin-top: 16px;
    white-space: nowrap;
    transition:
        opacity 0.6s $app-default-easing,
        transform 0.6s $app-default-easing;

    @include laptop {
        margin-top: 14px;
    }

    @include tablet {
        margin-top: 10px;
    }

    @include mobile {
        margin-top: 0;
    }
}

.catalog-category-name {
    margin-top: 46px;
    transition:
        opacity 0.6s $app-default-easing,
        transform 0.6s $app-default-easing;
    opacity: 1;
    transform: translateY(0);
    z-index: 1;

    @include laptop {
        margin-top: 28px;
    }

    @include tablet {
        margin-top: 24px;
    }

    @include mobile {
        margin-top: 0;
    }

    .catalog-category-card--main & {
        margin-top: 86px;

        @include laptop {
            margin-top: 60px;
        }

        @include tablet {
            margin-top: 24px;
        }

        @include mobile {
            margin-top: 0;
        }
    }
}

@include mobile {
    .swiper-slide:not(.swiper-slide-active):not(.swiper-slide-duplicate-active) {
        .catalog-category-amount {
            transform: translateY(10px);
            opacity: 0;
        }
        .catalog-category-name {
            opacity: 0;
            transform: translateY(15px);
        }
        .catalog-category-card-lottie {
            opacity: 0;
            transform: translateY(20px);
        }
    }
}

.catalog-category-slider {
    max-width: 100%;

    &.swiper {
        overflow: visible;
    }

    .swiper-wrapper {
        display: flex;
        align-items: flex-end;
    }

    .swiper-slide {
        padding-top: 48px;
    }
}

.catalog-category-slider-wrapper {
    max-width: 64%;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
}

.catalog-category-description {
    display: contents;

    @include mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 9px;
    }
}

.catalog-category-card-lottie-wrapper {
    display: contents;

    @include mobile {
        width: 64px;
        height: 64px;
        display: block;
    }
}
