.search-input-label {
    width: 100%;
}

.search-input-submit svg path {
    stroke: $white;
}

.search-input-clear,
.search-input-submit {
    .btn__icon {
        --size: 22px;
    }
}

.search-input-form {
    position: relative;
}

.search-input-buttons {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    gap: 12px;
}

.search-input {
    padding-right: 60px;
    --autofill-bg-color: #{$black};

    @include mobile {
        @include responsive-font-size(13px, 14px, 360px, 768px);
    }
}
