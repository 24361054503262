.error-page__canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow: clip;
}

.error-page__canvas-el {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
