.share-wrapper {
    position: relative;
}

.share-message {
    position: absolute;
    top: 50%;
    left: calc(100% + 10px);
    transform: translateY(-50%);
}
