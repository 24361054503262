.product-video-popup {
    visibility: hidden;
    transition: visibility 0s ease;
    transition-delay: 0.35s;
    z-index: 17;

    .popup-container {
        color: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        width: auto;

        @include tablet {
            width: 50%;
        }

        @include mobile {
            width: 85%;
        }
    }

    .popup-overlay {
        background-color: $black;
        opacity: 0.74;
    }

    &.popup--opened {
        visibility: visible;
        transition-delay: 0s;
    }

    .popup-container {
        @include hidden-scrollbar;
    }

    .product-video__btns-wrapper {
        position: relative;
        left: 9%;
        bottom: 8%;
        display: flex;
        width: 80%;

        @include laptop {
            bottom: 8%;
        }

        @include mobile {
            left: 19%;
            width: 60%;
            bottom: 10%;
        }
    }

    .video-player-controls {
        left: 10%;
        bottom: 10%;
        width: 80%;

        @include mobile {
            left: 20%;
            width: 60%;
            bottom: 12%;
        }
    }

    .review-card__control {
        top: auto;
        left: 0;
        transform: none;
    }

    .video-popup-sound {
        position: absolute;
        right: 0;
    }

    .review-card__control,
    .video-popup-sound {
        --padding-y: 4px;
    }
}

.product-video-popup__inner {
    height: 90svh;

    @include tablet {
        height: 60svh;
    }
}
