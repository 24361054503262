.blog-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 16px;

    @include laptop {
        gap: 16px 8px;
    }
}

.checkbox-button {
    input[type='checkbox'],
    input[type='radio'] {
        display: none;
    }

    .mustache {
        transition:
            transform $app-default-duration $app-default-easing,
            opacity $app-default-duration $app-default-easing;
    }

    .btn-geometry-mustache {
        margin-inline: 0;
    }

    &:not(.checkbox-button--checked) {
        .mustache {
            opacity: 0;
        }
    }
}

.checkbox-button-inner {
    display: flex;
    align-items: center;
    gap: 8px;
}

.checkbox-button-inner__count {
    opacity: 0.6;
}

.blog-filters-count {
    opacity: 0.6;
    margin-left: 6px;
}
