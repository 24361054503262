.catalog-landing-popup {
    visibility: hidden;
    transition: visibility 0s ease;
    transition-delay: 1s;

    .popup-container {
        --offset-y: 130px;
        color: $white;
        padding-top: calc(var(--header-height) + var(--offset-y));
        padding-bottom: var(--offset-y);

        &::before {
            content: '';
            pointer-events: none;
            position: fixed;
            top: inherit;
            bottom: inherit;
            left: inherit;
            width: inherit;
            height: inherit;
            background-color: $black-100;
            opacity: 0;
            transition: opacity 0.8s $app-default-easing;
            transition-delay: 0.2s;
        }

        @include laptop {
            --offset-y: 64px;
        }

        @include vertical-tablet {
            --offset-y: 75px;
        }

        @include mobile {
            --offset-y: 60px;
        }
    }

    &.popup--opened {
        visibility: visible;
        transition-delay: 0s;

        .popup-container {
            &::before {
                transition-delay: 0s;
                transition-duration: 1s;
                opacity: 1;
            }
        }
    }
}

.catalog-landing-step {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    @include tablet {
        align-items: center;
        --top-offset: 100px;
        flex-direction: column;
        top: calc(var(--header-height) + var(--top-offset));
        height: calc(100svh - var(--app-nav-height) - var(--header-height) - var(--top-offset));
    }

    &.catalog-landing-step--active {
        pointer-events: auto;
    }

    &.catalog-landing--animating {
        pointer-events: none;
    }
}

.catalog-landing-step--second {
    @include tablet {
        flex-direction: row;
        flex-wrap: wrap;
    }

    @include horizontal-mobile {
        flex-wrap: nowrap;
    }
}

.catalog-landing-first-item {
    flex: 1 1 calc(100% / 3);
    display: flex;
}

.catalog-landing-first-item__button {
    width: 100%;
    height: 100%;
    padding-top: 30px;

    @include laptop {
        padding-top: 49px;
    }

    @include tablet {
        padding-top: 0;
    }

    @include mobile {
        .btn-geometry-large {
            .btn__text {
                font-size: 20px;
                line-height: 1.1;
            }
        }
    }

    @include hover {
        .hat {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.catalog-landing-second-item__button {
    width: 100%;
    height: 100%;
    position: relative;

    .catalog-landing-card {
        position: absolute;
        top: 0;
        left: 0;
        padding-bottom: 7.1875vw;
        align-items: flex-end;

        @include laptop {
            padding-bottom: 11.6vw;
        }

        @include horizontal-large-tablet {
            padding-bottom: 21.6vw;
        }

        @include tablet {
            padding-bottom: 44px;
        }

        @include mobile {
            padding-bottom: 0px;
        }
    }

    @include tablet {
        .btn-geometry-large {
            .btn__text {
                font-size: 20px;
            }
        }
    }

    @include mobile {
        .btn-geometry-large {
            .btn__text {
                font-size: 13px;
                line-height: 1.3;
            }
        }
    }

    @include hover {
        .hat {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.catalog-landing-second-item {
    flex: 1 1 calc(100% / 5);
    display: flex;
    flex-direction: column;

    &:nth-child(5n + 1),
    &:nth-child(5n + 2) {
        @include tablet {
            width: 50%;
        }

        @include mobile {
            width: 33.3%;
        }
    }

    &:nth-child(5n + 3) {
        @include tablet {
            width: 33.3%;
        }
    }

    &:nth-child(5n + 4),
    &:nth-child(5n + 5) {
        @include tablet {
            width: 33.3%;
        }

        @include mobile {
            width: 50%;
        }
    }

    &:nth-child(5n + 1),
    &:nth-child(5n + 2),
    &:nth-child(5n + 3),
    &:nth-child(5n + 4),
    &:nth-child(5n + 5) {
        @include horizontal-mobile {
            width: 20%;
        }
    }

    &:nth-child(5) {
        .catalog-landing-lottie-wrapper {
            padding-top: 90%;
        }

        .catalog-landing-card__name {
            width: 75%;
            margin-top: 26px;

            @include laptop {
                margin-top: 6px;
            }

            @include tablet {
                margin-top: 21px;
            }

            @include mobile {
                margin-top: 6px;
            }
        }
    }

    @include tablet {
        flex: none;
        width: 33.3%;
        height: 50%;

        .catalog-landing-step--second--two-categories & {
            width: 100%;
        }
    }

    @include horizontal-mobile {
        width: 20%;
        height: 100%;
    }
}

.catalog-landing-description {
    --top-offset: 106px;
    position: absolute;
    top: calc(var(--header-height) + var(--top-offset));
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 26px;
    opacity: 0.65;

    @include laptop {
        --top-offset: 43px;
    }

    @include mobile {
        --top-offset: 30px;
    }
}
