.blog-page {
    --top-offset: 56px;
    padding-top: calc(var(--header-height) + var(--top-offset));

    @include laptop {
        --top-offset: 48px;
    }

    @include tablet {
        --top-offset: 38px;
    }

    @include mobile {
        --top-offset: 16px;
    }
}

.blog-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 73px;

    @include laptop {
        margin-top: 60px;
    }

    @include tablet {
        gap: 86px;
    }

    @include mobile {
        gap: 60px;
    }
}

.blog-subscribe {
    align-self: flex-end;
    max-width: 280px;
    @include grid-offset(margin-right, 3);

    @include laptop {
        max-width: 260px;
        @include grid-offset(margin-right, 2);
    }

    @include tablet {
        position: relative;
        align-self: flex-start;
    }

    @include mobile {
        margin: 0;
        text-align: left;
        align-items: flex-start;
    }
}

.blog-title {
    display: flex;
    gap: 6px;
}

.blog-title__count {
    opacity: 0.6;
}

.blog-list {
    display: flex;
    flex-wrap: wrap;
}

.blog-list__item {
    @include grid-columns(8);

    @include tablet {
        @include grid-columns(12);
    }

    @include mobile {
        width: 100%;
    }

    &:nth-child(10n + 1) {
        @include grid-offset(margin-left, 8);

        @include tablet {
            @include grid-offset(margin-left, 12);
        }

        @include mobile {
            margin-left: 0;
        }
    }

    &:nth-child(10n + 4),
    &:nth-child(10n + 7),
    &:nth-child(10n + 8) {
        @include grid-offset(margin-right, 8);

        @include tablet {
            @include grid-offset(margin-right, 12);
        }

        @include mobile {
            margin-right: 0;
        }
    }

    &:nth-child(10n + 7) {
        @include tablet {
            margin-right: 0;
        }
    }

    &:nth-child(10n + 8) {
        @include tablet {
            @include grid-offset(margin-left, 12);
            margin-right: 0;
        }

        @include mobile {
            margin-left: 0;
        }
    }

    &:nth-child(10n + 5) {
        @include grid-columns(16);
        @include grid-offset(margin-left, 8);

        @include tablet {
            @include grid-columns(24);
            margin-left: 0;
        }

        @include mobile {
            width: 100%;
        }
    }

    &:nth-child(10n) {
        @include grid-columns(16);
        @include grid-offset(margin-right, 8);

        @include tablet {
            @include grid-columns(24);
            margin-right: 0;
        }

        @include mobile {
            width: 100%;
        }
    }

    .article-item {
        height: 672px;

        .article-item-bg img {
            transition: transform 1.2s $app-default-easing;
        }

        @include hover {
            .article-item-bg img {
                transform: scale(1.2, 1.2);
            }
        }

        @include laptop {
            height: 480px;
        }

        @include tablet {
            height: 420px;
        }

        @include mobile {
            height: 365px;
        }
    }

    .article-item__title {
        max-width: 464px;

        @include laptop {
            max-width: 350px;
        }
    }
}

.article-items-list__item,
.article-items-list-slider__item {
    .article-item {
        min-height: calc(100vh - var(--header-height) - var(--offset-top, 0px) - var(--article-top-height, 0px));
        height: 100%;
    }

    .article-item__inner {
        @include tablet {
            padding-bottom: calc(var(--app-nav-height) + 56px);
        }

        @include mobile {
            padding-bottom: calc(var(--app-nav-height) + 20px);
        }
    }
}

.blog-filters {
    display: flex;
    gap: 260px;
    width: 100%;
    justify-content: space-between;
    margin-top: 110px;
    position: relative;

    @include laptop {
        gap: 160px;
        margin-top: 100px;
    }

    @include tablet {
        gap: 1px;
        justify-content: flex-end;
        margin-top: -112px;
    }

    @include mobile {
        margin-top: 40px;
        justify-content: flex-start;
    }
}

.blog__list {
    margin-top: 80px;

    @include laptop {
        margin-top: 60px;
    }

    @include tablet {
        margin-top: 130px;
    }

    @include mobile {
        margin-top: 40px;
    }
}
