.product-slider {
    max-width: 100%;
    max-height: 100%;

    .input-block {
        position: absolute;
    }

    .product-video-btn,
    .video-player-controls,
    .video-popup-sound {
        transition: opacity $app-default-easing 0.8s;
        opacity: 0;
    }

    .video-player-controls {
        bottom: 15%;

        @include laptop {
            bottom: 20%;
        }
    }

    .product-video__btns-wrapper {
        position: relative;
        left: 24%;
        bottom: 10%;
        display: flex;
        width: 50%;

        @include laptop {
            bottom: 15%;
        }
    }

    .product-video-btn {
        top: 0;
        left: 0;
        transform: none;

        svg {
            width: 11px;
            height: 11px;
        }
    }

    .video-popup-sound {
        position: absolute;
        top: 0;
        right: 0;
    }

    .product-video-btn,
    .video-popup-sound {
        @include laptop {
            --padding-x: 10px;
            --padding-y: 5px;
        }

        @include tablet {
            --padding-x: 8px;
            --padding-y: 2px;
        }
    }
}

.product-slider-thumbs {
    position: absolute;
    top: calc(var(--header-height) + var(--offset-top));
    right: 19px;
    z-index: 2;

    @include tablet {
        --offset-top: 40px;
        top: 0;
    }

    @include mobile {
        right: var(--offset-x);
    }

    .swiper {
        height: 100%;
        overflow: visible;
    }
}

.product-slider-main.swiper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible;
}

.product-slider-main-responsive {
    --aspect-ratio: 1;
}

.product-slider-main-wrapper {
    --offset: 187px;
    width: calc(100% - var(--offset) * 2);
    margin-inline: auto;

    @include laptop {
        --offset: 100px;
    }

    @include tablet {
        --offset: 32px;
    }

    @include mobile {
        --offset: 30px;
    }
}

.product-slide-main-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
}

.video-progress-slider {
    position: absolute;
    left: 25%;
    bottom: 0;
    width: 50%;
}

.thumbs-slide {
    --padding-x: calc(var(--mustache-width) + var(--mustache-offset));
    --mustache-main-width: 5px;
    --mustache-before-width: 3px;
    --mustache-width: calc(var(--mustache-main-width) + var(--mustache-before-width));
    --mustache-offset: 6px;
    padding-inline: var(--padding-x);
    cursor: pointer;

    &.swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @include laptop {
        --mustache-offset: 2px;
    }

    .mustache {
        opacity: 0;
        transition:
            opacity $app-default-duration * 2 $app-default-easing,
            transform $app-default-duration * 2 $app-default-easing;
    }

    &.swiper-slide-thumb-active {
        .mustache {
            opacity: 1;
        }
    }

    @include hover {
        .mustache {
            opacity: 1;
        }
        .mustache-left {
            transform: translateX(calc(var(--mustache-offset)));
        }
        .mustache-right {
            transform: translateX(calc(var(--mustache-offset) * -1));
        }
    }
}

.thumbs-slide__image,
.thumbs-slide__video {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
}

.swiper-slide-active {
    .product-video-btn,
    .video-player-controls,
    .video-popup-sound {
        opacity: 1;
    }
}

.video-player-controls {
    position: absolute;
    left: 25%;
    bottom: 10px;
    width: 50%;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.video-player-controls__top {
    display: flex;
    opacity: 0.75;
}

.video-player-controls__top-right {
    margin-left: auto;
}

.video-player-controls__timeline {
    @include clickable();
    position: relative;
    width: 100%;
    height: 2px;
    display: flex;
    background-color: rgba(#fff, 0.5);
}

.video-player-controls__timeline-el {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #fff;
    transform-origin: 0% 50%;
    will-change: transform;
}

.video-player-controls__timeline-circle {
    background-color: #fff;
    display: block;
    flex-shrink: 0;
    width: 2px;
    height: 10px;
    position: absolute;
    left: -2px;
    top: calc(50% - 10px);
}
