@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes rotateReverse {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

.catalog-preloader {
    --bg-color: #{$black};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    z-index: 2;
    background-color: $black;
}

.catalog-preloader-main-circle {
    --size: 85px;
    width: var(--size);
    height: var(--size);
    border: 1px solid #fff;
    border-radius: 50%;
    position: relative;
    animation: rotate 4s linear infinite;
    background-color: var(--bg-color);
    position: sticky;
    top: 50vh;
    margin-top: calc(var(--size) / -2);
}

.catalog-preloader-medium-circle {
    --size: 42px;
    width: var(--size);
    height: var(--size);
    background-color: var(--bg-color);
    border-radius: 50%;
    border: 1px solid #fff;
    position: absolute;
    top: -1px;
    left: 50%;
    margin-left: calc(var(--size) / -2);
}

.catalog-preloader-medium-circle-inner {
    --size: 10px;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: var(--bg-color);
    position: absolute;
    right: calc(var(--size) / -2);
    top: 50%;
    margin-top: calc(var(--size) / -2);
    animation: rotateReverse 2s linear infinite;
    transform-origin: -15px;
}

.catalog-preloader-small-circle {
    --duration: 2.5s;
    --size: 8px;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: var(--bg-color);
    position: absolute;
    right: calc(var(--size) / -2);
    top: 50%;
    margin-top: calc(var(--size) / -2);
    animation: rotateReverse var(--duration) linear infinite;
    transform-origin: -39px;
    z-index: 1;
}

.catalog-preloader-small-circle--first {
    animation-delay: calc(var(--duration) / 3 * -1);
}
.catalog-preloader-small-circle--second {
    animation-delay: calc(var(--duration) / 3 * -2);
}
.catalog-preloader-small-circle--third {
    animation-delay: calc(var(--duration) / 3 * -3);
}
