.text-trade-in {
    position: absolute;
    font-size: 80px;
}

.lunar-section {
    min-height: 600vh;
    width: 100%;
}

.lunar-section-inner {
    position: sticky;
    top: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.lunar-section__canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow: clip;
}

@keyframes rotateTuda {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes rotateSuda {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

.lunar-calendar-description {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: calc(var(--size, 100%) / -2);
    margin-top: calc(var(--size, 100%) / -2);
    z-index: 1;
    width: var(--size, auto);
    height: var(--size, auto);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    gap: 34px;
    cursor: pointer;

    @include mobile {
        gap: 10px;
    }

    @include hover {
        .mustache-left {
            transform: translateX(calc(var(--mustache-offset) * -1));
        }
        .mustache-right {
            transform: translateX(var(--mustache-offset));
        }
    }
}

.lunar-calendar-description__text {
    max-width: 205px;
    min-height: 86px;
    min-height: 5lh;

    @include laptop {
        max-width: 185px;
    }

    @include tablet {
        max-width: 177px;
    }

    @include mobile {
        font-size: 11px;
        min-height: 53px;
        min-height: 4lh;
        max-width: 162px;
    }
}
