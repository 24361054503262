.feedback-popup {
    visibility: hidden;
    transition: visibility 0s ease;
    transition-delay: 0.4s;
    z-index: 17;

    .popup-overlay {
        opacity: 0;
        transition: opacity 0.4s ease;
    }

    .popup-container {
        width: 695px;
        height: auto;
        top: 0;
        right: 0;
        left: unset;
        @include hidden-scrollbar;

        @include large-desktop {
            width: 36.2vw;
        }

        @include laptop {
            width: 564px;
        }

        @include tablet {
            width: 100%;
            height: inherit;
        }
    }

    &.popup--opened {
        visibility: visible;
        transition-delay: 0s;

        .popup-overlay {
            opacity: 0.5;
        }
    }
}

.feedback-popup__body {
    will-change: height;
    color: $white;
    min-height: 100%;
    background-color: $black;
    display: flex;
    flex-direction: column;
    gap: 2px;
    max-height: 100vh;
}

.feedback-popup__inner {
    padding: 40px 24px 120px 24px;
    display: flex;
    flex-direction: column;
    background-color: $black-100;

    @include laptop {
        padding: 30px 20px 70px 20px;
    }

    @include tablet {
        padding: 24px 36px 246px 36px;
    }

    @include mobile {
        padding: 24px 16px 80px 16px;
        padding-bottom: calc(80px + 100lvh - 100dvh);
    }
}

.feedback-form-title {
    margin-bottom: 66px;
    display: flex;
    align-items: flex-start;
    gap: 12px;

    @include laptop {
        margin-bottom: 43px;
    }

    @include vertical-tablet {
        margin-bottom: 150px;
    }

    @include mobile {
        margin-bottom: 66px;
    }
}

.feedback-form-title__icon {
    flex: none;
    margin-top: 0.2em;

    .btn__icon {
        width: 33px;
        height: 14px;
    }
}

.feedback-form-title__text {
    opacity: 0.3;
    @include responsive-font-size(24px, 28px, 1280px, 1920px);
    letter-spacing: -0.03em;
    line-height: 1.14;

    @include tablet {
        @include responsive-font-size(18px, 24px, 360px, 768px);
        line-height: 1.22;
    }
}

.feedback-popup-close-button {
    position: absolute;
    top: 30px;
    right: 30px;
}

.feedback-form-buttons {
    display: flex;
    gap: 4px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    @include tablet {
        flex-direction: column;
        gap: 60px;
    }

    @include vertical-tablet {
        gap: 80px;
    }

    @include mobile {
        gap: 50px;
    }
}

.feedback-popup__hint {
    padding: 54px 16px;
    background-color: $black-100;
}

.hint-title {
    margin-bottom: 60px;

    @include tablet {
        margin-bottom: 64px;
    }
}

.hint-description {
    color: rgba(255, 255, 255, 0.7);

    &:not(:last-child) {
        margin-bottom: 72px;

        @include tablet {
            margin-bottom: 64px;
        }
    }
}

.hint-list {
    @extend .list-unstyled;
    color: rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: column;
    gap: 56px;

    @include tablet {
        gap: 48px;
    }

    li {
        position: relative;
        padding-left: 25px;

        @include mobile {
            padding-left: 20px;
        }

        &::before {
            --size: 5px;
            content: '';
            position: absolute;
            top: 0.5em;
            left: 0;
            width: var(--size);
            height: var(--size);
            background-color: rgba(255, 255, 255, 0.7);
        }
    }
}
