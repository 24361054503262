.new-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-pagination-ctrl {
    @include clickable();
}

.new-pagination-ctrl.btn-geometry-mustache {
    align-items: flex-start;

    @include laptop {
        align-items: center;
    }
}

.new-pagination-ctrl.btn-geometry-mustache.btn-sm {
    --padding-y: 7px;

    @include mobile {
        --padding-x: 12px;
        --padding-y: 5px;
    }
}

.new-pagination-ctrl--prev {
    margin-right: 30px;

    @include mobile {
        margin-right: 20px;
    }
}

.new-pagination-ctrl--next {
    transform: rotate(180deg);
    margin-left: 30px;

    @include mobile {
        margin-left: 20px;
    }
}

.new-pagination-link {
    opacity: 0.5;
    transition: opacity $app-default-duration $app-default-easing;

    & + & {
        margin-left: 16px;

        @include tablet {
            margin-left: 12px;
        }

        @include mobile {
            margin-left: 12px;
        }

        @media screen and (max-width: 359px) {
            margin-left: 8px;
        }
    }
}

a.new-pagination-link {
    position: relative;

    &::after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 30px;
        height: 30px;
    }

    &::before {
        width: 30px;
        height: 30px;
    }

    @include hover {
        opacity: 1;
    }
}

.new-pagination-link--active {
    opacity: 1;
    pointer-events: none;
}
