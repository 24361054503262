.filter-checkbox {
    user-select: none;
    transition: opacity $app-default-easing $app-default-duration;

    &.filter-checkbox--checked {
        opacity: 0.5;
    }

    &.filter-checkbox--bordered {
        position: relative;
        width: max-content;
        border-radius: 60px;
        border: 1px solid rgba($white, 0.5);
        padding: 3px 12px;
        padding-right: 12px;

        .btn__text {
            display: flex;
            align-items: center;
            gap: 6px;
        }
    }

    .btn {
        min-height: unset;
    }
}

.filter-checkbox__input {
    display: none;
}

.filter-checkbox__cross {
    display: inline-flex;
}

.filter-checkbox__text.btn .btn__text {
    @include tablet {
        @include responsive-font-size(14px, 16px, 360px, 1024px);
    }
}

.all-brands-list {
    .filter-checkbox__cross {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
    }

    .filter-checkbox__text.btn .btn__text {
        position: relative;
        padding-right: 20px;
    }

    @include tablet {
        .filter-checkbox {
            .btn {
                text-align: left;
            }
        }
    }
}
