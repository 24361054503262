.catalog-page,
.search-page {
    --top-offset: 56px;
    padding-top: calc(var(--header-height) + var(--top-offset));

    @include laptop {
        --top-offset: 48px;
    }

    @include tablet {
        --top-offset: 38px;
    }

    @include mobile {
        --top-offset: 16px;
    }

    .dropdown {
        padding-top: 30px;

        .select-toggler {
            svg {
                opacity: 0.6;
                transition:
                    opacity 0.25s $app-default-easing,
                    transform 0.5s $app-default-easing;
            }
        }

        &.dropdown--opened {
            .select-toggler {
                svg {
                    opacity: 1;
                    transform: rotate(180deg);
                }
            }
            &:not(:last-child) {
                padding-bottom: 40px;

                @include mobile {
                    padding-bottom: 26px;
                }
            }
            .dropdown-btn {
                &::before {
                    height: 33%;

                    @include mobile {
                        height: 65px;
                    }
                }
            }
            .dropdown-content {
                height: auto;
                margin-top: 40px;
                opacity: 1;
            }
        }

        &:not(:last-child) {
            padding-bottom: 10px;
            border-bottom: 1px solid rgba($gray, 0.3);
        }
    }

    .dropdown-content {
        position: static;
        opacity: 0;
        height: 0px;
        margin-top: 0px;
        transition:
            opacity 0.25s $app-default-easing,
            height 0.5s $app-default-easing,
            margin-top 0.5s $app-default-easing;
    }

    .dropdown-btn {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: default;
        }
    }

    .collapse {
        position: relative;

        padding-top: 30px;
        &:not(:last-child) {
            padding-bottom: 10px;
            border-bottom: 1px solid rgba($gray, 0.3);
        }

        .collapse__toggler {
            svg {
                opacity: 0.6;
                transition:
                    opacity 0.75s $app-default-easing,
                    transform 0.75s $app-default-easing;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                cursor: default;
            }
        }

        &.collapse--opened {
            .collapse__toggler {
                svg {
                    opacity: 1;
                    transform: rotate(180deg);
                }

                &::before {
                    height: 33%;

                    @include mobile {
                        height: 65px;
                    }
                }
            }
        }
    }

    .collapse__content {
        transition: height 0.75s ease;
    }

    .filter-row {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .card-list-title__text {
        margin-top: 0;
    }
}

.catalog-page {
    .catalog-filters {
        display: flex;
        bottom: var(--offset-x);
    }

    .catalog-search-in-catalog {
        @include tablet {
            margin-top: auto;
        }

        @include mobile {
            margin-top: 32px;
        }
    }

    .catalog-filters--all-pages {
        display: none;
    }

    .callback-and-phone-buttons {
        --gap: 1px;

        @include tablet {
            width: calc(35% - var(--offset-x) - var(--gap));
        }

        @include mobile {
            width: calc((100% - var(--offset-x) * 2) * 0.56 - var(--gap));
            gap: 8px;
        }

        @include small-mobile {
            gap: 4px;
        }
    }
}

.cards-list-pagination-btns-wrapper {
    display: flex;
    justify-content: center;
    width: calc(100% - 28px);

    @include tablet {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: auto;
    }

    &.pagination-wrapper-double {
        justify-content: space-between;
    }
}

.load-more-btn {
    width: fit-content;

    .btn__inner,
    .btn__copy {
        gap: 12px;

        @include mobile {
            --padding-x: 16px;
        }
    }

    .btn__icon {
        --size: 12px;

        @include mobile {
            --size: 8px;
        }
    }
}

.cards-list {
    display: flex;
    flex-wrap: wrap;
    --gap: 2px;
    gap: var(--gap);
    margin-top: 168px;
    width: 100%;
    position: relative;

    @include laptop {
        margin-top: 120px;
    }

    @include tablet {
        margin-top: 90px;
    }

    @include mobile {
        width: calc(100% + var(--offset-x) * 2);

        &.cards-list--compare {
            margin-left: calc(var(--offset-x) * -1);
        }
    }
}

.cards-list__item {
    width: calc(var(--grid-column-width) * 6 - var(--gap));
    flex: none;

    @include tablet {
        width: calc(50% - var(--gap));
    }

    @include mobile {
        width: calc(50% - var(--gap));
        order: 2;
    }

    .card {
        height: 100%;
    }

    .m-text-xs {
        @include responsive-font-size(12px, 16px, 1280px, 1920px);
    }
}

.cards-list__item--wide {
    width: calc(var(--grid-column-width) * 12 - var(--gap));

    @include tablet {
        width: calc(100% - var(--gap));
    }

    @include mobile {
        width: calc(50% - var(--gap));
    }
}

.card-list-title__text {
    width: min-content;
    margin-top: auto;
}

.card-list-title--popular {
    padding-left: 78px;

    @include large-desktop {
        padding-left: 4.0625vw;
    }

    @include laptop {
        padding-left: 42px;
    }

    @include tablet {
        padding-left: 45px;
    }
}

.card-list-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @include mobile {
        padding-bottom: 35px;
        width: 100%;
        order: 1;
        padding-inline: var(--offset-x);
    }
}

.catalog-top {
    display: flex;
    --gap: 2px;
    gap: var(--gap);

    @include tablet {
        flex-direction: column;
        position: relative;
    }

    @include mobile {
        display: contents;
    }
}

.catalog-top__main {
    width: calc(var(--grid-column-width) * 6 - var(--gap));
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: none;

    @include tablet {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
    }

    @include mobile {
        display: contents;
        flex-direction: column;
        margin-bottom: 81px;
    }
}

.catalog-to-main {
    @include tablet {
        width: 100%;
    }

    @include mobile {
        order: 1;
        .search-page & {
            order: -1;
        }
    }
}

.catalog-top__right {
    display: flex;
    align-items: flex-start;
    --gap: 2px;
    gap: var(--gap);
    position: relative;
    width: 100%;
    justify-content: flex-end;

    @include tablet {
        &.catalog-top__right--single {
            justify-content: flex-start;
        }
    }

    @include mobile {
        &:not(.catalog-top__right--single) {
            margin-left: calc(var(--offset-x) * -1);
            width: calc(100% + var(--offset-x) * 2);
            overflow: hidden;
            max-width: calc(100% + var(--offset-x) * 2);
        }
        margin-top: 81px;
        order: 4;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}

.catalog-find-product {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 64px;
    padding-right: 64px;
    margin-left: auto;
    max-width: calc(var(--grid-column-width) * 12);
    transform: translateY(calc(var(--offset) * -1));
    margin-bottom: calc(var(--offset) * -1);
    min-height: var(--offset);
    padding-top: 40px;
    padding-bottom: 20px;

    &.catalog-find-product--with-one-category {
        transform: translate(calc(var(--grid-column-width) * -7), -100%);

        @include tablet {
            transform: translate(0, -100%);
        }

        @include mobile {
            transform: none;
        }
    }

    @include laptop {
        padding-top: 32px;
        padding-right: 0;
        gap: 18px;
    }

    @include tablet {
        padding-right: 30px;
        transform: none;
        margin-bottom: unset;
        gap: 24px;
        padding-bottom: 0;
    }

    @include mobile {
        max-width: 100%;
        align-items: flex-start;
        text-align: left;
        padding-bottom: 0;
        padding-top: 0;
        padding-right: 0;
        order: 3;
    }

    .btn-geometry-mustache {
        margin-inline: 0;
    }
}

.catalog-subscribe-tg {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 64px;
    margin-top: auto;

    @include laptop {
        gap: 18px;
    }

    @include tablet {
        gap: 24px;
        position: absolute;
        left: 0;
        max-width: calc(var(--grid-column-width) * 8);
        top: calc(100% + 30px);
    }

    @include mobile {
        position: relative;
        top: unset;
        left: unset;
        max-width: 75%;
        margin-inline: auto;
        align-items: center;
        text-align: center;
        margin-top: 66px;
        order: 7;
    }

    .btn-geometry-mustache {
        margin-inline: 0;
    }
}

.subscribe-tg-btn {
    .btn__icon {
        --size: 16px;
    }
    .btn__inner,
    .btn__copy {
        gap: 8px;

        @include mobile {
            --padding-x: 14px;
        }
    }
}

.catalog-wrapper {
    display: flex;
    flex-direction: column;
}

.catalog-find-product__text {
    max-width: 400px;

    @include laptop {
        max-width: 307px;
    }

    @include tablet {
        max-width: 289px;
    }

    @include mobile {
        max-width: 100%;
    }
}

.catalog-page-title {
    margin-top: 76px;
    margin-bottom: 10px;
    display: flex;
    padding-right: 32px;

    @include laptop {
        margin-top: 62px;
    }

    @include tablet {
        margin-top: 70px;
        margin-bottom: 0;
    }

    @include mobile {
        width: 100%;
        margin-top: 46px;
        margin-bottom: 32px;
        align-self: center;
        order: 2;
    }
}

.catalog-page-title__text {
    position: relative;
}

.catalog-search {
    margin-top: 64px;
    --offset: 24px;
    width: calc(100% - var(--offset));

    @include laptop {
        --offset: 12px;
        margin-top: 52px;
    }

    @include tablet {
        width: 50%;
    }

    @include tablet {
        margin-top: 32px;
        margin-bottom: 20px;
    }
}

.cards-list-with-pagination {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    position: relative;

    @include laptop {
        gap: 52px;
    }

    @include tablet {
        gap: 42px;
    }

    @include mobile {
        gap: 36px;
    }
}

.catalog-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-inline: auto;
    gap: 64px;
    margin-top: 168px;
    max-width: calc(var(--grid-column-width) * 4);

    .search-page & {
        display: none;
    }

    @include laptop {
        gap: 18px;
        margin-top: 120px;
        max-width: calc(var(--grid-column-width) * 6);
    }

    @include tablet {
        gap: 24px;
        margin-top: 90px;
        max-width: calc(var(--grid-column-width) * 12);
    }

    @include mobile {
        max-width: 75%;
    }
}

.catalog-search-wrapper {
    display: flex;
    margin-top: 56px;

    @include laptop {
        margin-top: 48px;
    }

    @include mobile {
        flex-direction: column;
    }

    .catalog-search {
        width: 25%;

        @include tablet {
            width: 50%;
        }

        @include mobile {
            width: 100%;
        }
    }
}

.catalog-search-in-catalog {
    margin-top: auto;

    @include tablet {
        width: 100%;
    }

    @include tablet {
        margin-top: 32px;
        margin-bottom: 20px;
    }
}

.catalog-search-mobile {
    margin-top: 90px;
    @media screen and (min-width: 1200px) {
        display: none;
    }

    @include mobile {
        order: 6;
    }
}

.catalog-list {
    @include mobile {
        order: 7;
    }
}

.breadcrumbs {
    display: flex;
    margin-left: 26px;
    margin-top: 13px;
    gap: 8px;
    align-self: flex-start;

    @include mobile {
        margin-left: 20px;
        margin-top: 4px;
    }
}

.breadcrumbs-current {
    pointer-events: none;
}

.catalog-title-count {
    opacity: 0.6;
    position: absolute;
    left: calc(100% + 6px);
    top: 0.3em;
}

.mobile-filters {
    display: flex;
    margin-top: auto;
    margin-bottom: 26px;
    --offset: 24px;
    width: calc(100% - var(--offset));
    justify-content: space-between;
    gap: 1px;

    @include laptop {
        --offset: 12px;
    }

    @include mobile {
        width: 100%;
        margin-top: 36px;
        margin-bottom: 0;
    }
}
