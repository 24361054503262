.blog-sort {
    position: relative;
}

.blog-filter-btn {
    @include tablet {
        width: 140px;

        .btn {
            width: 100%;
        }
    }
}

.blog-sort-popup-portal {
    @media screen and (min-width: 768px) {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;

        .sort-popup {
            position: absolute;
            width: auto;
            height: auto;
            left: unset;
            top: 0;
            right: 0;
            overflow: unset;

            .popup-container {
                left: unset;
                top: 0;
                right: 0;
            }
        }
    }

    .popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }

    .sort-popup__main {
        @include tablet {
            max-height: 50vh;
            overflow-y: auto;
        }

        @include mobile {
            max-height: 100vh;
            max-height: 100dvh;
        }
    }
}
