.card {
    --x-offset: 20px;
    display: flex;
    flex-direction: column;
    padding: 44px var(--x-offset) 32px var(--x-offset);
    background-color: $black-100;
    transition: background-color $app-default-easing $app-default-duration;
    position: relative;
    overflow: hidden;
    overflow: clip;

    @include laptop {
        padding: 24px var(--x-offset) 28px var(--x-offset);
    }

    @include hover {
        background-color: $black-200;
    }

    @include mobile {
        padding: 24px 16px;
    }
}

.card-default {
    .card-characteristics-key {
        padding-left: 11px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0.33em;
            left: 0;
            --size: 0.33em;
            width: var(--size);
            height: var(--size);
            background-color: $white;
            border-radius: 50%;
        }
    }

    .card-price {
        transition: transform $app-default-duration $app-default-easing;
    }
}

.card-index {
    .card-characteristics-key {
        &::before {
            content: '[ ';
        }

        &::after {
            content: ' ]';
        }
    }
}

.card-price {
    display: flex;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;

    @include laptop {
        margin-top: 12px;
    }

    @include mobile {
        justify-content: space-between;
    }

    .card-index & {
        margin-left: auto;
        width: calc(50% - var(--gap) / 2);
        order: 4;
        text-align: right;
        justify-content: flex-end;

        @include laptop {
            width: calc(45% - var(--gap) / 2);
            margin-top: 12px;
        }
    }
}

.card-header {
    display: flex;
    --gap: 12px;
    gap: var(--gap);
    margin-bottom: 28px;
    flex-wrap: wrap;

    @include laptop {
        margin-bottom: 20px;
    }
}

.card-state {
    transition:
        transform $app-default-duration $app-default-easing,
        opacity $app-default-duration $app-default-easing;
    flex: none;
    width: calc(50% - var(--gap) / 2);
    order: 1;
}

.card-name {
    transition: transform $app-default-duration $app-default-easing;
    height: 46px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;

    @include responsive-height(13px, 14px, 1280px, 1920px, 1.28, 1);

    @include laptop {
        @include responsive-height(13px, 14px, 1280px, 1920px, 1.07, 1);
    }

    @include tablet {
        height: 13px;
    }

    .card-index & {
        max-width: 260px;
        order: 3;
        width: calc(50% - var(--gap) / 2);
        -webkit-line-clamp: 3;

        @include responsive-height(13px, 14px, 1280px, 1920px, 1.28, 3);

        @include laptop {
            width: calc(55% - var(--gap) / 2);
            @include responsive-height(13px, 14px, 1280px, 1920px, 1.07, 3);
        }

        @include tablet {
            height: 39px;
        }

        @media screen and (max-width: 1366px) {
            max-width: 200px;
        }
    }
}

.card-favorite {
    flex: none;
    order: 2;
    width: calc(50% - var(--gap) / 2);
    display: flex;
    gap: 20px;
    justify-content: flex-end;

    .btn {
        .btn__icon {
            --size: 20px;
        }

        @include tablet {
            --size: 18px;
        }

        @include mobile {
            .btn__icon {
                --size: 16px;
            }

            .btn__inner,
            .btn__copy {
                padding: 0;
            }
        }
    }
}

.card.card-width-wide {
    @media screen and (min-width: 768px), (min-width: 901px) and (orientation: landscape) {
        .card-header {
            flex-wrap: nowrap;
            align-items: center;
        }

        .card-state {
            order: unset;
            width: unset;
            margin-right: auto;
        }

        .card-name {
            order: unset;
            width: unset;
        }

        .card-price {
            order: unset;
            width: unset;
            margin-left: auto;

            &:not(:last-child) {
                margin-right: 14px;
            }
        }

        .card-favorite {
            order: unset;
            width: unset;
        }
    }
}

.card-image {
    --aspect-ratio: 1;

    img {
        object-fit: contain;
    }
}

.card-center {
    flex-shrink: 0;
    max-width: 360px;
    align-self: center;
    position: relative;
    margin-bottom: 25px;
    width: 100%;
    overflow: hidden;
    overflow: clip;

    @include laptop {
        margin-bottom: 15px;
        max-width: 267px;
    }

    @include mobile {
        margin-bottom: 8px;
    }

    .swiper {
        overflow: visible;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.card-characteristics {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 26px;

    @include laptop {
        gap: 20px;
    }
    .card-default & {
        margin-top: 25px;
        padding-top: 42px;
        border-top: 2px solid $black;

        @include laptop {
            padding-top: 26px;
        }

        @include mobile {
            margin-top: 22px;
            padding-top: 22px;
        }
    }
}

.card-characteristics-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    opacity: 0.4;
}

.card-characteristics-value {
    text-align: right;
}

.card-available {
    display: flex;
    margin-top: 32px;
    padding-top: 32px;
    border-top: 2px solid #0d0d0d;

    @include laptop {
        padding-top: 26px;
    }

    @include tablet {
        margin-top: 12px;
    }

    @include mobile {
        padding-top: 22px;
    }
}

.card-available__text {
    padding-left: 11px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0.33em;
        left: 0;
        --size: 0.33em;
        width: var(--size);
        height: var(--size);
        background-color: $primary;
        border-radius: 50%;

        @include mobile {
            top: 0.5em;
        }
    }
}

.card-footer {
    display: flex;
    flex-direction: column;
    max-width: 468px;
    align-self: center;
    width: 100%;
    height: 100%;

    @include laptop {
        max-width: 316px;
    }
}

.card-favorite--index {
    margin-top: 64px;
    align-self: center;

    @include laptop {
        margin-top: 36px;
    }
}

.price--delimeter,
.price--rouble {
    opacity: 0.4;
}

.zero-price {
    display: flex;
    opacity: 0.4;
}

.favorite-button-default {
    @include clickable;
}

.favorite-wrapper,
.compare-wrapper {
    position: relative;
}

.favorite-message,
.compare-message {
    position: absolute;
    top: 50%;
    left: calc(100% + 10px);
    transform: translateY(-50%);
}

.favorite-message__link,
.compare-message__link {
    text-decoration: underline;
    cursor: pointer;
}
