.main-video-section {
    height: 100vh;
    height: 100svh;

    .video-popup__video video {
        height: 100vh;
        height: 100svh;
    }

    .main-video {
        @include mobile {
            position: relative;
        }
    }

    .video-player-controls {
        left: 10%;
        bottom: 0;
        gap: 15px;

        @include laptop {
            left: 15%;
        }

        @include tablet {
            left: 25%;
        }

        @include mobile {
            left: 0;
            bottom: 35px;
            gap: 10px;
            width: 100%;
        }
    }

    .video-player-controls__timeline {
        height: 2px;
    }

    .video-player-controls__timeline-arrow {
        background-color: #fff;
        display: block;
        width: 2px;
        height: 10px;
        position: absolute;
        left: -2px;
        top: calc(50% - 10px);
        flex-shrink: 0;
    }

    .video-player__player,
    .video-popup-btns_wrapper {
        bottom: 0;
        opacity: 0;
        visibility: hidden;
        transition:
            bottom 1s $app-default-easing,
            opacity 1s $app-default-easing;
    }

    .video-player__play,
    .video-popup-btns_wrapper {
        .btn__icon {
            --size: 20px;

            @include laptop {
                --size: 16px;
            }

            @include mobile {
                --size: 12px;
            }
        }

        .btn__inner {
            --padding-x: 18px;

            @include mobile {
                --padding-x: 14px;
            }
        }
    }

    .video-player__player {
        @include mobile {
            width: 95%;
        }
    }

    .video-player__play {
        @include mobile {
            transform: translateX(20%);
        }
    }

    .video-popup-btns_wrapper {
        @include mobile {
            transform: translateX(-10%);
        }
    }
}

.main-video-section.is-fullscreen {
    .video-popup__video video {
        @include horizontal-large-tablet {
            object-fit: contain;
        }

        @include tablet {
            object-fit: contain;
        }

        @include horizontal-mobile {
            object-fit: cover;
        }
    }

    .main-video_togglers {
        bottom: 0px;
        opacity: 0;
        visibility: hidden;
    }

    .video-player__player,
    .video-popup-btns_wrapper {
        bottom: 50px;
        opacity: 1;
        visibility: visible;
        transition: opacity 1s $app-default-easing;

        @include laptop {
            bottom: 30px;
        }

        @media screen and (max-width: 1179px) {
            bottom: 31vh;
            bottom: 31svh;
        }

        @include horizontal-large-tablet {
            bottom: 15vh;
            bottom: 15svh;
        }

        @include horizontal-tablet {
            bottom: 15vh;
            bottom: 15svh;
        }

        @include mobile {
            bottom: 38vh;
            bottom: 38svh;
        }

        @include horizontal-mobile {
            bottom: 10px;
        }
    }

    .btn-geometry-mustache {
        --padding-y: 2px;
    }
}

.main-video.main-video--idle {
    .video-player__player,
    .video-popup-btns_wrapper {
        opacity: 0;
    }
}

.main-video-content {
    position: relative;
    display: flex;
    flex-direction: column;
}

.main-video_togglers {
    position: absolute;
    bottom: -10%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%);
    transition:
        bottom 1.5s $app-default-easing,
        opacity 1s $app-default-easing;

    &.is-ready {
        bottom: 48px;
        opacity: 1;
        visibility: visible;

        @include laptop {
            bottom: 30px;
        }
        @include tablet {
            bottom: 100px;
        }
        @include mobile {
            bottom: 85px;
        }
    }

    @include laptop {
        width: 30%;
    }

    @include tablet {
        width: 45%;
    }

    @include mobile {
        width: 85%;
    }
}

.main-video-controls,
.main-video-btns_wrapper {
    position: static;
    width: auto;
}

.main-video-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 34px;

    @include laptop {
        margin-bottom: 16px;
    }

    @include mobile {
        gap: 5px;
    }

    svg:last-child {
        transform: rotate(-180deg);
    }
}

.main-video-btns_wrapper {
    display: flex;
    gap: 90px;

    @include laptop {
        gap: 75px;
    }

    .btn__icon {
        --size: 20px;

        @include laptop {
            --size: 16px;
        }

        @include mobile {
            --size: 12px;
        }
    }

    @include mobile {
        justify-content: space-between;
        gap: 35px;
        width: 100%;
    }
}

.video-popup-fullscreen,
.video-popup-sound,
.video-popup-tg {
    @include mobile {
        .btn__inner {
            --padding-x: 16px;
        }
    }
}

.video-popup-tg {
    .btn__inner {
        gap: 12px;
    }

    .btn__text {
        order: 2;
    }

    .btn__icon {
        order: 1;
    }
}

.main-video-controls__top {
    display: flex;
    gap: 2px;
    opacity: 1;
}

.main-video-controls__top-right {
    margin-left: auto;
}

.main-video-title {
    margin-bottom: 48px;
    text-align: center;
    text-transform: uppercase;

    @include laptop {
        margin-bottom: 30px;
    }
}
