.time {
    display: flex;
    align-items: center;
    position: relative;
    padding-inline: 10px;

    @include tablet {
        padding-inline: 14px;
    }
}

.time-decor {
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: $white;

    &::before {
        position: absolute;
        content: '';
        top: 50%;
        background-color: $white;
        height: 1px;
        width: 4px;
    }
}

.time-decor-left {
    left: 0;

    &::before {
        right: 100%;
    }
}

.time-decor-right {
    right: 0;

    &::before {
        left: 100%;
    }
}
