.about-page-to-main {
    --top-offset: 58px;
    position: sticky;
    top: calc(var(--header-height) + var(--top-offset));
    left: var(--offset-x);
    z-index: 2;

    @include tablet {
        --top-offset: 38px;
    }

    @include mobile {
        --top-offset: 28px;
    }
}

.about-page {
    .footer {
        background-color: $gray-brown;
        color: $white;
    }
}
