.link {
    --bg-size: 100% 100%;
    --bg-size-hover: 0% 100%;
    --underline-height: 1px;
    background-image: linear-gradient(
        transparent calc(100% - var(--underline-height)),
        currentColor var(--underline-height)
    );
    background-repeat: no-repeat;
    background-size: var(--bg-size);
    transition: background-size $app-default-duration $app-default-easing;

    @include hover {
        --color: var(--color-hover);
        background-size: var(--bg-size-hover);
    }

    &.link--underlined {
        --bg-size: 100% 100%;
        --bg-size-hover: 0% 100%;
    }
}
