.service-card {
    --bottom-offset: 0px;
    background-color: $black-100;
    padding: 112px 70px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 80px;
    position: relative;
    height: calc(
        100vh -
            (var(--offset-top, 0px) + var(--title-height, 0px) + var(--title-offset, 0px) + var(--bottom-offset, 0px))
    );
    transition: background-color 0.5s ease;

    @include tablet {
        --bottom-offset: calc(var(--app-nav-small-height) + 64px);
    }

    @include mobile {
        height: 100vw;
        gap: 44px;
        padding: 130px 29px 74px 28px;
    }

    @include hover {
        background-color: $black-200;

        .service-card-canvas {
            opacity: 1;
        }
    }
}

.service-card__top {
    display: flex;
    flex-direction: column;
    gap: 60px;
    align-items: center;

    @include mobile {
        gap: 46px;
    }
}

.service-card__title {
    text-transform: uppercase;
}

.serivce-card__description {
    max-width: 292px;
}

.service-card-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease;
    opacity: 0;
    pointer-events: none;
}

.service-card-slide.swiper-slide-active .service-card-canvas {
    @include tablet {
        opacity: 1;
    }
}
