.compare-list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    --gap: 2px;
    gap: var(--gap);
}

.compare-list__item {
    --columns: 4;
    width: calc((100% / var(--columns)) - ((var(--columns) - 1) / var(--columns)) * var(--gap));
    flex: none;

    @include tablet {
        --columns: 2;
    }
}

.compare-list-wrapper {
    margin-left: calc(var(--offset-x) * -1);
    padding-inline: var(--offset-x);
    width: calc(100% + var(--offset-x) * 2);
    overflow: hidden;

    .swiper {
        overflow: visible;
    }
}

.compare-list__item--add {
    background-color: $black-100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    width: 100%;
    height: 100%;
    padding: 40px;

    @include laptop {
        gap: 42px;
        padding: 36px;
    }

    @include tablet {
        gap: 36px;
        padding: 26px;
    }

    @include mobile {
        gap: 40px;
        padding: 16px;
    }
}

.compare-list__empty {
    flex-grow: 1;
}

.compare-list__empty-slide.swiper-slide {
    height: auto;
}

.compare-list--fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: var(--header-height);
    height: auto;
    padding-inline: var(--offset-x);
    background-color: $black;
    z-index: 16;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-bottom: 2px solid $black-100;
    overflow: hidden;
    transition: transform $app-default-easing 0.6s;
    transform: translateY(-100%);

    .swiper {
        max-width: 100%;
        overflow: visible;
        height: auto;
        flex-grow: 1;
        display: flex;
    }

    .swiper-wrapper {
        height: auto;
    }

    &.compare-list--fixed-visible {
        transform: translateY(0%);
    }

    .compare-list {
        flex-grow: 1;
    }
}

.compare-swiper-top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #181818;
    padding: 24px 40px;
    margin-bottom: 2px;

    @include mobile {
        padding: 24px 16px;
    }
}

.compare-btn-wrapper {
    display: flex;
    gap: 30px;

    .swiper-button-prev svg {
        transform: rotate(-180deg);
    }
}
