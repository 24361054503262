.product-layout {
    display: flex;
    --offset-top: 60px;
    min-height: 100vh;
    --back-button-height: 76px;

    @include laptop {
        --offset-top: 16px;
    }

    @include tablet {
        min-height: calc(100vh - var(--header-height) - var(--back-button-height));
    }

    @include mobile {
        flex-direction: column;
        gap: 60px;
    }
}

.product-left {
    padding-top: calc(var(--header-height) + var(--offset-top));
    background-color: $black-100;
    padding-inline: var(--offset-x);
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: sticky;
    top: 0;
    width: 25%;
    order: -1;

    @include tablet {
        position: relative;
        top: unset;
        max-height: unset;
        padding-top: var(--offset-top);
        padding: 0;
        width: 100%;
        border-bottom: 2px solid $black;
    }

    .collapse {
        position: relative;
    }
}

.product-left__top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 60px;
    margin-bottom: 43px;
    position: relative;
    width: 100%;

    @include laptop {
        margin-bottom: 33px;
    }

    @include tablet {
        display: none;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: calc(100% + var(--offset-x) * 2);
        left: calc(var(--offset-x) * -1);
        height: 2px;
        background-color: $black;
    }
}

.product-left__center {
    display: flex;
    flex-direction: column;
    gap: 90px;
    width: 100%;
    padding-bottom: 24px;
    position: relative;

    @include tablet {
        gap: 0;
        padding-top: 50px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            width: calc(100% + var(--offset-x) * 2);
            left: calc(var(--offset-x) * -1);
            height: 2px;
            background-color: $black;
        }
    }

    @include mobile {
        padding-top: 32px;
    }
}

.product-center {
    background-color: $black;
    --offset-top: 24px;
    padding-top: calc(var(--header-height) + var(--offset-top));
    padding-inline: 32px;
    max-height: 100vh;
    position: sticky;
    top: 0;
    overflow: hidden;
    overflow: clip;
    width: 50%;

    @include laptop {
        padding-inline: 28px;
    }

    @include tablet {
        --offset-top: 196px;
        height: calc(100vh - var(--header-height) - var(--back-button-height));
        height: calc(100lvh - var(--header-height) - var(--back-button-height));
        max-height: unset;
        padding-top: var(--offset-top);
    }

    @include mobile {
        width: 100%;
        position: relative;
        --offset-top: 0px;
        padding-top: 0;
        padding-inline: 16px;
        height: auto;
    }
}

.product-right {
    padding-top: calc(var(--header-height) + var(--offset-top));
    background-color: $black-100;
    width: 25%;

    @include tablet {
        padding-top: var(--offset-top);
        padding: 0;
        width: 100%;
    }
}

.product-columns {
    display: contents;

    @include tablet {
        display: flex;
        flex-direction: column;
        width: 50%;
        min-height: calc(100vh - var(--header-height) - var(--back-button-height));
        min-height: calc(100lvh - var(--header-height) - var(--back-button-height));
        background-color: $black-100;
    }

    @include mobile {
        min-height: unset;
        width: 100%;
    }
}

.product-left__available {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 100%;
    padding-bottom: 43px;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: calc(100% + var(--offset-x) * 2);
        left: calc(var(--offset-x) * -1);
        height: 2px;
        background-color: #0d0d0d;
    }

    @include laptop {
        padding-bottom: 33px;
    }

    @include tablet {
        padding-top: 33px;
        padding-inline: var(--offset-x);

        &::before {
            content: '';
            position: absolute;
            top: 0;
            width: calc(100% + var(--offset-x));
            left: calc(var(--offset-x) * -1);
            height: 2px;
            background-color: #0d0d0d;
        }

        &::after {
            width: calc(100% + var(--offset-x));
        }
    }
}

.product-all-available--top {
    position: absolute;
    right: var(--offset-x);
    border: none;
}

.product-available-text {
    position: relative;
    width: fit-content;
    padding-right: 20px;
    text-transform: uppercase;
    color: $primary;

    &::after {
        content: '';
        position: absolute;
        top: 0.33em;
        right: 0;
        --size: 0.5em;
        width: var(--size);
        height: var(--size);
        background-color: $primary;
    }
}

.product-available-subtext {
    opacity: 0.4;
    text-transform: uppercase;
}

.product-all-available {
    display: flex;
    opacity: 0.4;

    @include tablet {
        display: inline-block;
    }
}

.collapsed-product-all-available {
    position: absolute;
    top: 33px;
    right: 18px;

    @include mobile {
        top: 28px;
    }
}

.button-arrow-reverse .btn__icon svg {
    transform: scaleX(-1);
}

.product-state {
    float: left;

    @include mobile {
        &.btn-sm {
            --padding-y: 7px;
        }
    }
}

.product-name {
    text-indent: 16px;
    line-height: 34px;
    letter-spacing: 0;
}

.product-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @include tablet {
        margin-bottom: 85px;
    }

    @include mobile {
        margin-bottom: 27px;
    }
}

.product-price {
    display: flex;
    flex-wrap: wrap;
}

.text-price {
    @include responsive-font-size(16px, 20px, 1280px, 1920px);
    line-height: 1.17;
    letter-spacing: -0.03em;
    text-transform: uppercase;

    @include laptop {
        line-height: 1.13;
    }

    @include tablet {
        font-size: 16px;
        line-height: 1.21;
    }

    @include mobile {
        font-size: 18px;
        line-height: 1.3;
    }
}

.product-cta {
    display: flex;
    width: 100%;
    margin-top: auto;
    padding-top: 24px;
    position: relative;

    .btn {
        width: 50%;

        .btn__outer,
        .btn__copy,
        .btn__inner {
            justify-content: center;
        }
    }
}

.product-trade-in-button {
    border-right: none;
}

.product-collapse {
    padding-inline: var(--offset-x);
    border-bottom: 1px solid $black;
    transition: background-color $app-default-duration $app-default-easing;

    @include hover {
        &:not(.collapse--opened) {
            background-color: lighten($black-200, 1%);
        }
    }

    &.collapse--opened {
        background-color: $black-200;
    }

    .product-collapse-options,
    .product-characteristic-item__name,
    .product-characteristic-item__value,
    .button-arrow-reverse .btn__text {
        font-size: 12px;
    }

    @include mobile {
        width: 30%;

        .product-collapse-options {
            font-size: 10px;
        }

        .collapse__content {
            position: relative;
            right: var(--offset-x);
            background-color: $black-200;
            width: var(--product-right-width);
        }

        .product-collapse-content {
            padding-inline: var(--offset-x);
        }

        &:first-child {
            width: 40%;
        }

        &.collapse--opened {
            .collapse__content {
                transition-delay: var(--collapse-delay);

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &:nth-child(2) {
            .collapse__content {
                transform: translateX(-40%);
            }
        }

        &:nth-child(3) {
            .collapse__content {
                transform: translateX(-70%);
            }
        }
    }
}

.product-collapse-toggler {
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-block: 32px;

    @include laptop {
        padding-block: 24px;
    }

    @include mobile {
        justify-content: flex-start;
        gap: 10px;
        padding-block: 20px;
    }
}

.product-collapse-content {
    padding-block: 32px;

    @include laptop {
        padding-block: 24px;
    }

    @include mobile {
        padding-top: 24px;
    }
}

.product-collapse-toggler__icon {
    --size: 8px;
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform $app-default-easing $app-default-duration;

    [aria-expanded='false'] & {
        transform: rotate(-180deg);
    }
}

.product-characteristic {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include laptop {
        gap: 36px;
    }

    @include tablet {
        gap: 24px;
    }
}

.product-characteristic-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    text-transform: uppercase;
}

.product-characteristic-item__name {
    opacity: 0.6;
}

.product-collapse-text {
    opacity: 0.6;
    max-width: 83%;

    @include tablet {
        @include responsive-font-size(13px, 14px, 360px, 768px);
        line-height: 1.21;
    }

    @include mobile {
        line-height: 1.3;
    }
}

.product-buttons {
    position: absolute;
    left: 32px;
    top: calc(var(--header-height) + var(--offset-top));
    display: flex;
    flex-direction: column;
    gap: 26px;
    z-index: 2;

    @include laptop {
        left: 28px;
        gap: 16px;
    }

    @include tablet {
        --offset-top: 40px;
        left: var(--offset-x);
        flex-direction: row;
        gap: 16px;
        top: 0;
    }

    @include mobile {
        --offset-top: 0px;
    }
}

.product-button .btn__icon {
    --size: 24px;

    @include laptop {
        --size: 20px;
    }
}

.product-cards-list {
    .card-list-title__text {
        width: auto;
    }

    .cards-list__item,
    .cards-list__item--wide {
        @include mobile {
            width: calc(50% - var(--gap));
        }
    }

    .cards-list__item:last-child {
        margin-left: auto;
        margin-right: 2px;

        @include tablet {
            margin-inline: unset;
        }
    }
}

.product-page .page {
    @include tablet {
        padding-top: var(--header-height);
    }

    .product-cards-list {
        @include mobile {
            width: 100%;
        }
    }
}

.product-collapse-section-title {
    text-transform: uppercase;
    padding: 30px 18px;

    @include mobile {
        display: flex;
        justify-content: center;
        padding-block: 24px;
    }
}

.product-collapse-section-subtitle {
    position: absolute;
}

.product-collapse-section__inner {
    .product-left & {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 30px;
        padding-inline: var(--offset-x);

        @include tablet {
            padding-top: 0;
        }

        @include mobile {
            padding-top: 10px;
            padding-bottom: 24px;
        }
    }

    @include mobile {
        display: flex;
    }
}

.product-back-button-desktop {
    @include tablet {
        display: none;
    }
}

.product-back-button-touch {
    display: none;
    margin-left: var(--offset-x);
    margin-top: 36px;
    margin-bottom: 20px;

    @include tablet {
        display: flex;
    }

    @include mobile {
        margin-top: 28px;
        margin-bottom: 12px;
    }
}

.product-lower-price {
    display: flex;
    align-items: center;
    gap: 20px;

    @include tablet {
        margin-bottom: 0;
    }
}

.product-lower-price__text {
    opacity: 0.4;
    text-wrap: balance;

    @include mobile {
        font-size: 14px;
    }
}

.product-lower-price-button {
    margin-inline: 0;
    opacity: 0.4;

    @include mobile {
        .btn__text {
            font-size: 12px;
        }
    }
}

.product-zero-price {
    width: max-content;
    margin-left: 0;
    margin-bottom: 0;
}

.product-name-wrapper {
    @include tablet {
        margin-bottom: 100px;
    }

    @include mobile {
        margin-bottom: 44px;
    }
}

.btn-share {
    @include hover {
        .share_svg__arrow {
            transform: translateY(-2px);
        }
        .share_svg__not-arrow {
            transform: translateY(2px);
        }
    }
}

.share_svg__arrow,
.share_svg__not-arrow {
    transition: transform $app-default-duration * 2 $app-default-easing;
}

.product-proof {
    display: flex;
    margin-block: 48px;
    gap: 20px;
}

.product-proof__right {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include mobile {
        gap: 20px;

        .product-proof__title,
        .product-proof__description {
            font-size: 14px;
        }
    }
}

.product-proof__title {
    text-transform: uppercase;
}

.product-proof__description {
    opacity: 0.4;
    max-width: 228px;
}

.product-proof__image {
    width: 156px;
    height: 280px;
    position: relative;
    flex: none;
    overflow: hidden;
    overflow: clip;
    display: flex;
    justify-content: center;
    align-items: center;

    @include large-desktop {
        width: 8.125vw;
        height: 15vw;
    }

    @include laptop {
        width: 117px;
        height: 210px;
    }

    @include mobile {
        width: 118px;
        height: 210px;
    }

    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        z-index: 1;
        opacity: 0.4;
        transition: opacity 0.8s $app-default-easing;
    }

    @include hover {
        .product-proof__image-el {
            transform: scale(1.05, 1.05);
        }

        &::before {
            opacity: 0.2;
        }
    }
}

.product-proof__image-el {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1s $app-default-easing;
}

.product-proof__image-icon {
    --size: 16px;
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;

    @include mobile {
        --size: 12px;
    }

    svg {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
    }
}

.product-text-block {
    border: 1px solid rgba($white, 0.25);
    padding: 30px;

    @include mobile {
        padding: 21px;
    }

    &:not(:last-child) {
        margin-bottom: 48px;
    }
}

.product-text-block__title {
    &:not(:last-child) {
        margin-bottom: 18px;

        @include mobile {
            margin-bottom: 16px;
            font-size: 14px;
        }
    }
}

.product-text-block__text {
    opacity: 0.4;

    @include mobile {
        font-size: 12px;
    }
}
