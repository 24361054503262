.compare-card {
    background-color: $black-100;
    padding: 40px;
    display: flex;
    flex-direction: column;

    @include laptop {
        padding: 20px;
        padding-bottom: 24px;
    }

    @include mobile {
        padding: 24px 16px 16px 16px;
    }
}

.compare-card-small {
    background-color: $black;
    padding: 20px 40px 20px 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    border-right: 2px solid $black-100;
    height: 100%;

    @include laptop {
        padding: 12px 20px;
        gap: 10px;
    }

    @include tablet {
        padding: 20px;
    }

    @include mobile {
        padding: 16px;
    }
}

.compare-card-small__image {
    width: 56px;

    @include mobile {
        display: none;
    }
}

.compare-card__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    @include mobile {
        margin-bottom: 32px;
    }
}

.compare-card__image {
    max-width: 312px;
    align-self: center;
    position: relative;
    margin-bottom: 50px;
    width: 100%;

    @include laptop {
        margin-bottom: 38px;
        max-width: 216px;
    }

    @include tablet {
        margin-bottom: 64px;
    }

    @include mobile {
        max-width: 100%;
        margin-bottom: 28px;
    }
}

.compare-card__image-el {
    --aspect-ratio: 1;

    img {
        object-fit: contain;
    }
}

.compare-card__info {
    display: flex;
    flex-direction: column;
    gap: 38px;
    margin-bottom: 52px;
    cursor: grab;

    @include laptop {
        gap: 16px;
        margin-bottom: 40px;
    }

    @include tablet {
        margin-bottom: 38px;
    }

    @include mobile {
        margin-bottom: 32px;
    }
}

.compare-card-small__info {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include tablet {
        gap: 16px;
    }

    @include mobile {
        gap: 9px;
    }
}

.compare-card__buttons {
    margin-top: auto;

    @include mobile {
        flex-direction: column;
    }
    .btn {
        width: 50%;

        @include mobile {
            width: 100%;
        }

        .btn__outer,
        .btn__copy,
        .btn__inner {
            justify-content: center;
        }
    }
}

.compare-price {
    display: flex;
}

.compare-card__name {
    @include clamp(1);
}

.compare-card-small__price {
    gap: 20px;

    @include mobile {
        gap: 0px;
    }
}
