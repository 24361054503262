.main-page {
    .footer {
        background-color: $gray-brown;
        color: $white;
    }

    .page {
        padding-bottom: 0;
    }
}
