$thumb-size: 32px;
$thumb-size-mobile: 28px;

@mixin track-styles {
    appearance: none;
    background: transparent;
    border: transparent;
}

@mixin thumb-styles {
    appearance: none;
    pointer-events: all;
    width: $thumb-size;
    height: $thumb-size;
    border-radius: 0px;
    border: 0 none;
    cursor: grab;

    &:active {
        cursor: grabbing;
    }

    @include mobile {
        width: $thumb-size-mobile;
        height: $thumb-size-mobile;
    }
}

.range-slider-wrapper {
    --thumbs-size: 32px;
    position: relative;
    display: flex;
    align-items: center;
    margin-inline: calc(var(--thumbs-size) / 2);
    width: 100%;

    @include mobile {
        --thumbs-size: 28px;
    }
}

.range-slider-input-wrapper {
    width: calc(100% + var(--thumbs-size));
    margin-inline: calc(var(--thumbs-size) / -2);
    position: absolute;
    height: var(--thumbs-size);
    bottom: 0;
}

.range-slider-control-wrapper {
    width: 100%;
    position: absolute;
    height: var(--thumbs-size);
    bottom: 0;
}

.range-slider-input {
    position: absolute;
    width: 100%;
    pointer-events: none;
    appearance: none;
    height: 100%;
    opacity: 0;
    z-index: 3;
    padding: 0;

    &::-ms-track {
        @include track-styles;
    }

    &::-moz-range-track {
        @include track-styles;
    }

    &:focus::-webkit-slider-runnable-track {
        @include track-styles;
    }

    &::-ms-thumb {
        @include thumb-styles;
    }

    &::-moz-range-thumb {
        @include thumb-styles;
    }

    &::-webkit-slider-thumb {
        @include thumb-styles;
    }
}

.range-slider-rail {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    background-color: rgba($white, 0.4);
}

.range-slider-inner-rail {
    position: absolute;
    height: 100%;
    background-color: $white;
    transition: transform 0.3s ease;

    &.no-transition {
        transition: none;
    }
}

.range-slider-control {
    width: var(--thumbs-size);
    height: var(--thumbs-size);
    border-radius: 50%;
    position: absolute;
    background-color: $black-200;
    margin-left: calc(var(--thumbs-size) / -2);
    transform: translate3d(0, -50%, 0);
    z-index: 2;
    border: 1px solid $white;
    transition: transform 0.2s ease;

    &.no-transition {
        transition: none;
    }

    &::after {
        position: absolute;
        content: '';
        top: 50%;
        transform: translateY(-50%);
        right: 1px;
        width: calc(var(--thumbs-size) / 2);
        height: calc(var(--thumbs-size) / 2);
        border-radius: 50%;
        border: 1px solid $white;
    }
}

.range-slider-info {
    --offset: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% + var(--thumbs-size));
    margin-inline: calc(var(--thumbs-size) / -2);
    margin-bottom: calc(var(--thumbs-size) + var(--offset));
}

.range-slider-info__bracket {
    opacity: 0.5;
    transition:
        transform $app-default-easing 0.6s,
        opacity $app-default-easing 0.6s;
}

.range-slider__cross {
    opacity: 0.5;
}

.range-slider-info__item {
    display: flex;
    align-items: center;
    gap: 1ch;
}

.range-slider-text {
    display: inline-flex;
    align-items: center;

    @mixin active {
        .range-slider-info__bracket {
            opacity: 1;
        }

        .range-slider-info__bracket--left {
            transform: translateX(-3px);
        }

        .range-slider-info__bracket--right {
            transform: translateX(3px);
        }
    }

    @include hover {
        @include active;
    }

    &.is-focused {
        @include active;
    }
}

.range-slider-info__item,
.range-slider__cross.btn .btn__text {
    @include tablet {
        @include responsive-font-size(14px, 16px, 360px, 1024px);
    }
}

.range-slider-text-input {
    .input-block__input {
        border: none;
        border-bottom: none;
        padding: 0;
        @extend .m-text-xs;
        text-align: center;
    }

    &:focus {
        outline: none;
    }
}

.range-slider-text {
    .range-slider-text-input {
        .input-block__input {
            @include tablet {
                font-size: 14px;
            }
        }
    }
}
