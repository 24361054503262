.compare-tabs-content {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 168px;

    @include laptop {
        margin-top: 120px;
    }

    @include tablet {
        margin-top: 96px;
    }
}

.category-button {
    .mustache {
        transition:
            transform $app-default-duration $app-default-easing,
            opacity $app-default-duration $app-default-easing;
    }

    &:not(.category-button--active) {
        .mustache {
            opacity: 0;
        }
    }
}
