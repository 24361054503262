.favorite-item {
    display: flex;
    padding: 24px;
    gap: 20px;
    align-items: center;

    @include laptop {
        gap: 12px;
        padding: 24px 20px;
    }

    @include tablet {
        gap: 20px;
    }

    @include mobile {
        gap: 12px;
    }
}

.favorite-item-checkbox {
    margin-top: 6px;
    align-self: flex-start;
    margin-right: -8px;

    @include laptop {
        margin-right: -4px;
    }

    @include tablet {
        margin-right: -8px;
    }

    @include mobile {
        margin-right: -4px;
    }
}

.favorite-item__left {
    width: 129px;
    flex: none;

    @include laptop {
        width: 80px;
    }

    @include tablet {
        width: 120px;
    }

    @include mobile {
        width: 100px;
    }
}

.favorite-item__image {
    --aspect-ratio: calc(1 / 1);
}

.favorite-item__center {
    display: flex;
    flex-direction: column;
    gap: 28px;
    margin-right: auto;

    @include laptop {
        gap: 20px;
    }

    @include tablet {
        gap: 24px;
    }

    @include mobile {
        gap: 20px;
    }
}

.favorite-item-favorite-button {
    margin-inline: 0;
    --mustache-offset: 4px;
    --to-mustache-offset: 8px;
    margin-left: var(--to-mustache-offset);
    align-self: flex-start;

    @include clickable;
}

.favorite-item__name {
    max-width: 170px;
}

.checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-left: 30px;
    cursor: pointer;

    input[type='checkbox'] {
        &:checked {
            ~ .checkbox-element {
                &::before {
                    opacity: 1;
                }
            }
        }
    }

    @include hover {
        .checkbox-element {
            border-color: rgba($white, 0.4);
        }

        .checkbox-text {
            opacity: 0.6;
        }
    }
}

.checkbox-element {
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid rgba($white, 0.2);
    transition: border-color $app-default-easing $app-default-duration;
    --size: 20px;
    width: var(--size);
    height: var(--size);

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 6.2L5 9L9 3' stroke='%23F4F4F4' stroke-width='1.2' stroke-linecap='square'/%3E%3C/svg%3E%0A");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0;
        transition: opacity 0.1s ease;
    }
}

.checkbox-text {
    user-select: none;
    opacity: 0.3;
    transition: opacity $app-default-easing $app-default-duration;
}
