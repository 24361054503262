.feedback-form {
    --gap-x: 77px;
    --gap-y: 54px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: var(--gap-y) var(--gap-x);
    position: relative;
    padding-top: 20px;

    @include laptop {
        --gap-y: 42px;
        --gap-x: 62px;
    }

    .catalog-preloader {
        background-color: rgba($black-100, 0.74);
        --bg-color: #{$black-100};
    }

    .catalog-preloader-main-circle {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: calc(var(--size) / -2);
        margin-left: calc(var(--size) / -2);
    }
}

.feedback-form__field {
    width: calc(50% - var(--gap-x) / 2);

    @include vertical-tablet {
        width: 100%;
    }

    .input-block {
        min-height: 35px;
        display: flex;
        align-items: flex-end;
    }

    &.feedback-form__field--high {
        border-bottom: 1px solid rgba($white, 0.2);
        transition: border-color $app-default-duration $app-default-easing;
        transform: translateY(0);

        @include hover {
            border-color: $white;
        }

        .file-uploader-label {
            position: absolute;
            top: 0;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 79px;
            }

            &.has-files {
                transform: translateY(-24px);

                &::after {
                    transform: translateY(24px);
                }
            }
        }
        .input-block {
            min-height: 79px;
            display: flex;
            flex-direction: column;
        }
        .input-block__label {
            padding: 8px 0;
            top: 0;
            transform: translateY(0);
        }

        .input-block__input {
            flex-grow: 1;
            &:focus,
            &.not-empty {
                & + .input-block__label {
                    transform: translateY(-24px);
                }
            }
        }

        .app-message {
            top: calc(100% + 2px);
        }
    }
}

.feedback-form__footer {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    @include tablet {
        order: 1;
    }
}

.feedback-form__button {
    margin-inline: 0;
}

.feedback-form-status {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $black-100;
}
