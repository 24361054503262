.preloader {
    --padding-y: 80px;
    position: fixed;
    z-index: 100;
    overflow: hidden;
    overflow: clip;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--padding-y) var(--offset-x);

    @media screen and (max-width: 1366px) {
        --padding-y: 72px;
    }

    @include tablet {
        --padding-y: 60px;
    }

    @media screen and (min-width: 768px) and (max-width: 1199px) and (min-height: 950px) {
        --padding-y: clamp(60px, 16.5vmax, 169px);
    }

    @include mobile {
        --padding-y: 40px;
    }

    @media screen and (max-height: 500px) {
        --padding-y: 20px;
    }

    &.is-complete {
        background-color: transparent;
    }

    html.no-js & {
        display: none;
    }
}

.preloader__canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    transition: opacity 0.3s ease;

    &.is-hidden {
        opacity: 0;
    }
}

.preloader__bottom {
    margin-top: auto;
    text-transform: uppercase;

    html.no-scroll & {
        padding-right: var(--scrollbar-width, 0px);
    }
}

.preloader__counter-wrapper {
    margin-bottom: 10px;
}

.preloader__counter {
    transition: transform 0.75s easeOutQuart;

    .preloader.is-complete & {
        transform: translate3d(0, 105%, 0);
    }
}

.preloader__text {
    opacity: 0.5;
    transition: transform 0.75s easeOutQuart;

    .preloader.is-complete & {
        transform: translate3d(0, 105%, 0);
    }
}
