.categories-popup-content {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .category-button--active {
        opacity: 0.5;
        .mustache {
            opacity: 0;
        }

        &::after {
            content: 'X';
        }
    }

    .btn__inner,
    .btn__copy {
        padding: 0 var(--offset-x);
    }

    .btn-mustache-wrapper {
        .mustache-category-text,
        .favorites-count {
            font-size: 14px;
            font-weight: 500;
            line-height: 13px;
        }
    }
}
