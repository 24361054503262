.video-popup {
    visibility: hidden;
    transition: visibility 0s ease;
    transition-delay: 0.35s;
    z-index: 17;

    .popup-container {
        color: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        width: 65%;

        @include laptop {
            width: 75%;
        }
    }

    .popup-overlay {
        background-color: $black;
        opacity: 0.74;
    }

    &.popup--opened {
        visibility: visible;
        transition-delay: 0s;
    }

    .popup-container {
        @include hidden-scrollbar;
    }

    .video-player-controls {
        bottom: 2px;
        width: 100%;
        gap: 10px;

        @include mobile {
            gap: 5px;
        }
    }

    .video-player__play,
    .video-player-controls,
    .video-popup-btns_wrapper,
    .video-popup-close {
        transition: opacity 0.25s ease-in;
    }

    .review-card--idle {
        .video-player__play,
        .video-player-controls,
        .video-popup-btns_wrapper,
        .video-popup-close {
            opacity: 0;
        }
    }

    .video-player-controls__top {
        display: flex;
        opacity: 0.75;

        @include mobile {
            font-size: 8px;
        }
    }

    .video-player-controls__top-right {
        margin-left: auto;
    }

    .video-player-controls__timeline {
        @include clickable();
        position: relative;
        width: 100%;
        height: 2px;
        display: flex;
        background-color: rgba(#fff, 0.5);
    }

    .video-player-controls__timeline-el {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #fff;
        transform-origin: 0% 50%;
        will-change: transform;
    }

    .video-player-controls__timeline-arrow {
        --arrow-height: 10px;
        background-color: #fff;
        display: block;
        width: 2px;
        height: var(--arrow-height);
        position: absolute;
        left: -2px;
        top: calc(50% - var(--arrow-height));
        flex-shrink: 0;

        @include mobile {
            --arrow-height: 7px;
        }
    }

    .close-button,
    .btn-geometry-square-bracket.close-button {
        font-size: 14px;

        .btn__text {
            font-size: 14px;
        }

        @include mobile {
            font-size: 10px;

            .btn__text {
                font-size: 10px;
            }
        }
    }

    .video-popup-close.btn {
        transition: opacity 0.25s ease-in;

        .review-card--idle & {
            opacity: 0;
        }
    }

    .btn-geometry-mustache {
        --padding-y: 5px;

        @include laptop {
            --padding-x: 20px;
            --padding-y: 2px;
        }

        .btn__icon {
            --size: 14px;
        }

        @include tablet {
            --padding-x: 10px;
            --padding-y: 0px;

            .btn__icon {
                --size: 12px;
            }
        }

        @include mobile {
            .btn__icon {
                --size: 8px;
            }
        }
    }

    .btn-geometry-mustache.video-player__play {
        .btn__icon {
            --size: 10px;
        }

        @include tablet {
            --padding-x: 15px;
            --padding-y: 0px;

            .btn__icon {
                --size: 8px;
            }
        }

        @include mobile {
            --padding-x: 10px;
            .btn__icon {
                --size: 6px;
            }
        }
    }
}

.video-popup.is-fullscreen {
    .popup-container {
        width: 100%;
    }

    .popup-overlay {
        opacity: 1;
    }
}

.video-popup__inner {
    width: 100%;
}

.video-popup__video video {
    max-height: 100vh;
    max-width: 100%;
    width: 100%;
    object-fit: cover;
}

.video-popup-close {
    position: absolute;
    top: 50px;
    right: 50px;

    @include laptop {
        top: 25px;
        right: 25px;
    }

    @include mobile {
        top: 10px;
        right: 10px;
    }
}

.video-player__player {
    position: absolute;
    left: 50px;
    bottom: 50px;
    display: flex;
    gap: 50px;
    width: 50%;

    @include laptop {
        left: 25px;
        bottom: 25px;
        gap: 35px;
    }

    @include mobile {
        gap: 30px;
        left: 10px;
        bottom: 10px;
    }
}

.video-player-controls {
    display: flex;
    flex-direction: column;
}

.video-popup-btns_wrapper {
    position: absolute;
    right: 50px;
    bottom: 50px;
    display: flex;
    gap: 50px;

    @include laptop {
        right: 25px;
        bottom: 25px;
        gap: 35px;
    }

    @include mobile {
        gap: 30px;
        right: 10px;
        bottom: 10px;
    }
}
