.catalog-landing-card {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.catalog-landing-card__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.catalog-landing-card__amount {
    margin-top: 44px;

    @include laptop {
        margin-top: 28px;
    }

    @include tablet {
        margin-top: 24px;
    }

    @include mobile {
        margin-top: 0;
    }
}

.catalog-landing-card__name {
    margin-top: 69px;

    @include laptop {
        margin-top: 40px;
    }

    @include mobile {
        margin-top: 20px;
    }
}

.catalog-landing-lottie-outer {
    width: 216px;

    @include laptop {
        width: 180px;
    }

    @include horizontal-large-tablet {
        width: 220px;
    }

    @include tablet {
        width: 140px;
    }

    @include vertical-tablet {
        width: 200px;
    }

    @include mobile {
        width: 100px;
    }
}

.catalog-landing-lottie-wrapper {
    width: 100%;
    padding-top: 100%;
    height: 0;
    position: relative;
}

.catalog-landing-lottie {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
