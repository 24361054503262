.app-nav {
    display: flex;
    width: calc((50vw + var(--app-nav-catalog-button-width) / 2 - var(--offset-x) * 1));
    pointer-events: all;

    @include tablet {
        width: auto;
        margin-right: calc(var(--offset-x) * -1);

        &.app-nav--burger--opened {
            pointer-events: none;
        }
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.app-nav-catalog__icon {
    --size: 24px;
    width: var(--size);
    height: var(--size);
    border: 1px solid $black-100;
    border-radius: 50%;
    position: relative;
    animation: rotate 4s linear infinite;
    flex: none;

    @include laptop {
        --size: 18px;
    }

    @include tablet {
        --size: 16px;
    }

    &::before {
        --size: 10px;
        content: '';
        position: absolute;
        width: var(--size);
        height: var(--size);
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        border: 1px solid $black-100;
        border-radius: 50%;

        @include laptop {
            --size: 8px;
        }
    }
}

.app-nav-link {
    display: flex;
    text-transform: uppercase;
    align-items: center;
    padding: 15px;
    justify-content: center;
    gap: 10px;
    border: 1px solid rgba($white, 0.4);
    border-bottom: none;
    transition:
        transform 0.8s $app-default-easing,
        background-color 0.8s $app-default-easing;
    background-color: $black;
    height: var(--app-nav-small-height);
    outline-offset: -4px;

    [data-theme='black'] &:not(.app-nav-link--catalog) {
        background-color: $black;
    }

    [data-theme='gray-brown'] &:not(.app-nav-link--catalog) {
        background-color: $gray-brown;
    }

    @include laptop {
        padding: 12px;
    }

    @include tablet {
        width: unset;
        flex: 1 1 100%;
    }

    &:first-child,
    &:last-child {
        border-left: none;
        border-right: none;
    }
}

.app-nav-link--catalog {
    background-color: $white;
    color: $black-100;
    justify-content: space-between;
    border: none;
    padding: 20px 28px;
    transition:
        transform 0.8s $app-default-easing,
        opacity $app-default-duration $app-default-easing;
    transition-delay: 0s;
    width: var(--app-nav-catalog-button-width);
    height: auto;
    flex: none;

    @include laptop {
        padding: 16px;
    }

    @include tablet {
        height: var(--header-height);
        pointer-events: all;

        .app-nav--burger--opened & {
            opacity: 0;
            pointer-events: none;
        }
    }

    &:active {
        color: $black-100;
    }
}

.app-nav--burger--opened .app-nav-link--catalog {
    transition-delay: 0.6s;

    @include mobile {
        transition-delay: 0.45s;
    }
}

.app-nav-burger {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex: none;
    border: 1px solid rgba($white, 0.4);
    width: var(--app-nav-catalog-button-width);
    padding: 20px 28px;
    position: relative;
    background-color: $black;
    transition: background-color $app-default-duration $app-default-easing;
    pointer-events: all;

    @include tablet {
        width: var(--header-height);
        height: var(--header-height);
        border-left: 1px solid $black;
        background-color: $white;
        padding: 0;
    }

    &.is-opened {
        background-color: $black-100;

        @include tablet {
            background-color: $white;
        }

        .app-nav-burger__icon span {
            &:first-child {
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &:last-child {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }
}

.app-nav-burger__text {
    text-transform: uppercase;

    @include tablet {
        display: none;
    }
}

.app-nav-burger__icon {
    height: 10px;
    width: 25px;
    position: relative;

    span {
        width: 25px;
        height: 1px;
        background-color: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transition: transform $app-default-duration $app-default-easing;
        will-change: transform;

        @include tablet {
            background-color: $black;
        }

        &:first-child {
            transform: translate(-50%, calc(-50% + 2.5px));
        }

        &:last-child {
            transform: translate(-50%, calc(-50% - 2.5px));
        }
    }
}

.app-nav-link--catalog-text-wrapper {
    overflow: clip;
    overflow: hidden;
    position: relative;
    width: 100%;
    text-align: left;

    &::after {
        position: absolute;
        content: 'Закрыть';
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(50%);
        transition: transform $app-default-easing 0.4s;
    }

    &.is-opened {
        .app-nav-link--catalog-text-wrapper__text {
            transform: translateY(-100%);
        }
        &::after {
            transform: translateY(-50%);
        }
    }
}

.app-nav-link--catalog-text-wrapper__text {
    transition: transform $app-default-easing 0.4s;
}

.favorites-count {
    opacity: 0.6;
}

.app-nav-links {
    border: 1px solid rgba($white, 0.4);
    border-left-color: transparent;
    border-right-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
    padding-inline: 5px;
    background-color: $black;

    @include tablet {
        display: none;
    }
}

.app-nav-link__item {
    display: flex;
    gap: 8px;
    align-items: center;
    --offset: 20px;
    position: relative;

    @include laptop {
        --offset: 16px;
    }

    &:not(:first-child) {
        margin-left: var(--offset);
    }

    &:not(:last-child) {
        margin-right: var(--offset);

        &::after {
            content: '';
            position: absolute;
            left: calc(100% + var(--offset));
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 20px;
            background-color: rgba($white, 0.4);
        }
    }
}

.app-nav-link__item-icon {
    --size: 20px;
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    svg {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
    }

    @media screen and (max-width: 1680px) {
        --size: 16px;
    }
}

.app-nav-callback-button {
    display: flex;
    min-height: unset;
    --padding-x: 20px;

    .btn__outer {
        height: 100%;
    }

    @include laptop {
        --padding-x: 16px;
    }

    @include tablet {
        &.app-nav-callback-button--desktop {
            display: none;
        }

        flex-grow: 1;
        width: unset;
        margin-left: auto;
        z-index: 3;
        pointer-events: all;
        transition: opacity $app-default-duration $app-default-easing;

        .app-nav--burger--opened &,
        &.app-nav--burger--opened {
            opacity: 0;
            pointer-events: none;
        }

        .btn__inner,
        .btn__outer,
        .btn__copy {
            width: 100%;
            height: 100%;
            justify-content: center;
        }
    }

    @include small-mobile {
        --padding-x: 6px;
    }
}

.app-nav-link__item-count {
    line-height: 1;
    transform: translateY(-1px);
}

.menu-line {
    width: 100%;
    height: 2px;
    min-height: 2px;
    background-color: $black;
}
